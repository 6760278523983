
import { FC, memo, useCallback, useEffect, useRef } from 'react';
import { WalletHistoryProps } from './wallet-history.props';
import { WalletHistoryView } from './wallet-history.view';
import { useTelegramBack } from '../../../hooks/telegram-button';
import { useWallet } from '../wallet/wallet.props';
import { useAppDispatch, useTypedSelector } from '../../../hooks/use-typed-selector';
import { getWalletDataActionCreater } from '../../../store/reducers/personal-account/wallet-slice';
import { useUser } from '../../../hooks/user-hook';


export const WalletHistory: FC<WalletHistoryProps> = memo(() => {
  const wallet = useWallet();
  const onBack = useCallback(() => {
    wallet({});
  }, [wallet])
  useTelegramBack(onBack);
  const {user, authToken} = useUser();
  const walletState = useTypedSelector(state => state.wallet);

  const dispatch = useAppDispatch();

  const getWalletHistory = useCallback(() => {
      dispatch(getWalletDataActionCreater(authToken));
  }, [dispatch,authToken]);

  const hasFetched = useRef(false);

  useEffect(() => {
    if (!hasFetched.current) {
      getWalletHistory();
      hasFetched.current = true;
    }
  }, [dispatch, getWalletHistory]);

  return (
      <WalletHistoryView
        walletState={walletState}
        />
    );
});
