import { EventSourcePolyfill, MessageEvent } from 'event-source-polyfill';
import { EventName } from './sit-go/game-api';

export type SSEStatus = "pending" | "reconnect" | "ready" | "error";

export type StatusChangedSubscriberProps = (status: SSEStatus) => void;
export type SubscriberProps = (data: ChartDataProps[]) => void;

const subscribers = {
    "messages-received": [] as SubscriberProps[],
    "status-changed": [] as StatusChangedSubscriberProps[],
}

let source: EventSourcePolyfill | null = null;
let reconectAmount = 0;

const dataHandler = (ev: MessageEvent) => {

    //console.log(ev.data);
    const newData = JSON.parse(ev.data);
    reconectAmount = 0;
    subscribers["messages-received"].forEach(s => s(newData));
}

const errorHandler = (error: any) => {

    if((error.status === 400 || error.status === 500 || error.status === 404) || reconectAmount === 3){
        notifySubsribersAboutStatus("error");
        reconectAmount = 0;
        source?.close();
        return;
    }

    notifySubsribersAboutStatus("reconnect");
    console.log("reconnect", reconectAmount);
    reconectAmount++;
    source?.close();
}

const openHandler = () => {
    notifySubsribersAboutStatus("ready");
    console.log("ready")
}

const cleanUp = () => {
    source?.removeEventListener('error', errorHandler);
    source?.removeEventListener('message', dataHandler);
    source?.removeEventListener('open', openHandler);
}

const notifySubsribersAboutStatus = (status: SSEStatus) => {
    subscribers["status-changed"].forEach((s) => s(status));
}

function createChannel(authToken: string) {
    cleanUp();
    source?.close();
    source = new EventSourcePolyfill(`${process.env.REACT_APP_API_URL}/binance-data/v1`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    notifySubsribersAboutStatus("pending");
    source.addEventListener('error', errorHandler);
    source.addEventListener('message', dataHandler)
    source.addEventListener('open', openHandler);
}

export const chartApi = {
    start(authToken: string) {
        createChannel(authToken);
    },
    stop() {
        subscribers["messages-received"] = [];
        subscribers["status-changed"] = [];
        cleanUp();
        source?.close();
    },
    subscribe(eventName: EventName, callback: SubscriberProps | StatusChangedSubscriberProps) {
        //@ts-ignore
        subscribers[eventName].push(callback);
    },
    unsubscribe(eventName: EventName, callback: SubscriberProps | StatusChangedSubscriberProps) {
        //@ts-ignore
        subscribers[eventName] = subscribers[eventName].filter(s => s !== callback);
    },
}

export type ChartDataProps = {
    timestamp: string;
    value: number;
};