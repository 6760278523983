import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../..";
import { swiperApi } from "../../../api/swiper-api";
import { appStatusSlice } from "../app-status-slice";
import axios from "axios";

export type DailyTask = {
    id: number;
    icon: string;
    value: number;
    maxValue: number;
    title: string;
    subTask: SubTask[];
}

export type Claim = {
    id: number;
    userName: string;
    gameTaskId: number;
    ended: boolean;
    isCompleted: boolean;
    campaignId: number;
    value: number;
    btrtAmount: number;
}

export type SubTask = {
    id: number;
    icon: string;
    bonus: number;
    title: string;
    url: string;
    dailyTaskType: "ACHIEVEMENTS" | "SOCIAL";
    created: number[];
    ended: number[];
    userLimit: number;
    updated: any;
    completed: boolean;
}

export type SwiperTasksState = {
    loading: "idle" | "loading" | "success" | "error";
    dailyTasks: DailyTask[];
    mainTask: SubTask[];
    btrt: number;
}

const initialState: SwiperTasksState = {
    loading: 'loading',
    dailyTasks: [],
    mainTask: [],
    btrt: 0,

}

export const swiperTasksSlice = createSlice({
    name: 'swiper-tasks',
    initialState: initialState,
    reducers: {
        setDailyTasks(state, action: PayloadAction<DailyTask[]>) {
            state.dailyTasks = action.payload;
        },
        setLoading(state, action: PayloadAction<"idle" | "loading" | "success" | "error">) {
            state.loading = action.payload;
        },
        setNewTask(state, action: PayloadAction<DailyTask>) {
            state.dailyTasks.map((item) => item.id === action.payload.id ? action.payload : item);
        },
        resetCheckBalance(state){
            state.btrt = 0;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMainTask.fulfilled, (state, action: PayloadAction<SubTask[]>) => {
                state.mainTask = action.payload;
                state.loading = "success";
            })
            .addCase(getMainTask.pending, (state) => {
                state.loading = "loading";
            })
            .addCase(getMainTask.rejected, (state) => {
                state.loading = "error";
            })
            .addCase(claimTask.fulfilled, (state, action: PayloadAction<Claim>) => {
                state.dailyTasks = state.dailyTasks.map((item) => item.id !== action.payload.campaignId ? item
                    : {
                        ...item,
                        subTask: item.subTask.map((sub) =>
                            sub.id === action.payload.gameTaskId
                                ? { ...sub, completed: action.payload.isCompleted } : sub),
                        value: action.payload.value
                    }
                );
                //console.log(state.dailyTasks);
                //console.log(action.payload)
                state.btrt = action.payload.btrtAmount;

            })
            .addCase(claimMainTask.fulfilled, (state, action: PayloadAction<Claim>) => {
                state.mainTask = state.mainTask.map((item) => item.id === action.payload.gameTaskId ? { ...item, completed: action.payload.isCompleted } : item);
                state.btrt = action.payload.btrtAmount;
            })
    }
});

export const getAllTasksActionCreater = (authToken: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(swiperTasksSlice.actions.setLoading('loading'));
        swiperApi.getAllTasks(authToken)
            .then(responce => {
                dispatch(swiperTasksSlice.actions.setDailyTasks(responce));
                dispatch(swiperTasksSlice.actions.setLoading('success'));
            })
            .catch((err) => {
                dispatch(swiperTasksSlice.actions.setLoading('error'));
                if (err.response?.status === 401) {
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            })
    }
}

export const getMainTask = createAsyncThunk('swiper-tasks/main',
    async (data: { authToken: string }, thunkApi) => {
        const { authToken } = data;
        try {
            const responce = await swiperApi.getMainTasks(authToken);
            return responce;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            }
        }
    }
)

export const claimTask = createAsyncThunk('swiper-tasks/claim',
    async (data: { authToken: string, id: number }, thunkApi) => {
        const { authToken, id } = data;
        try {
            const responce = await swiperApi.checkTask(id, authToken);
            return responce;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            }
        }
    }
)

export const claimMainTask = createAsyncThunk('swiper-tasks/claim-main',
    async (data: { authToken: string, id: number }, thunkApi) => {
        const { authToken, id } = data;
        try {
            const responce = await swiperApi.checkTask(id, authToken);
            return responce;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkApi.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            }
        }
    }
)



export const checkTaskActionCreater = (idTask: number, authToken: string) => {
    return (dispatch: AppDispatch) => {
        swiperApi.checkTask(idTask, authToken)
            .then(responce => {
                dispatch(swiperTasksSlice.actions.setLoading('success'));
            })
            .catch((err) => {
                dispatch(swiperTasksSlice.actions.setLoading('error'));
                if (err.response?.status === 401) {
                    dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }));
                }
            })
    }
}

export default swiperTasksSlice.reducer;