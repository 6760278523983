import { walletApi } from "../../../api/wallet"



export const apiSendVerifyWithdraw = async ({
    code,
    authToken,
}: { authToken: string; code?: string }) => {
    if (code) {
        const response = await walletApi(authToken).verifyWithdraw(code);

        if (response.error) throw new Error(response.error.message || "Ошибка API");
        return response.data;
    }

    return false;
};