import { FC, memo } from "react"
import { Outlet } from "react-router";
import BitrateLogo from "../../images/bitrate-logo-new.svg";
import { useAuthentication } from "../../hooks/authentication-hook";
import { LoadingScreen, StyledLogoLoading } from "../../ui-kit/loading-view/loading-view";
import { OnboardingView } from "../../screens/onboarding";
import { useUser } from "../../hooks/user-hook";

export type PrivateRouteProps = {

}

export const PrivateRoute: FC<PrivateRouteProps> = memo(() => {
    const { loading } = useAuthentication();
    const { user } = useUser()
    return (
        loading !== "success" ?
            <LoadingScreen>
                <StyledLogoLoading src={BitrateLogo} />
            </LoadingScreen>
        : user.isFirstTime ? <OnboardingView /> : <Outlet />
    )
})