import { ChangeEvent, FC, memo, useCallback, useEffect, useState } from "react";
import { Column } from "../../../ui-kit/column";
import { Text } from "../../../ui-kit/text";
import { themes } from "../../../themes/theme";
import { Spacing } from "../../../ui-kit/spacing";
import { Tab, TabType } from "../../../ui-kit/Tab/tab";
import { Blur, ScrollContainer, StyledInput } from "./wallet.styled";
import { SurfaceWallet } from "../../../ui-kit/SurfaceWallet";
import { Row } from "../../../ui-kit/row";
import { Button } from "../../../ui-kit/button";
import {
  Currency,
  NetworkCurrency,
  networkTags,
} from "../../../request/api/wallet/dto/common";
import { Pressable } from "../../../ui-kit/pressable";
import { Surface } from "../../../ui-kit/surface";
import arrow from "../../../images/arrow-list-languages.svg";
import { Icon } from "../../../ui-kit/icon";
import warningSvg from "../../../ui-kit/assets/warning.svg";
import close from "../../../ui-kit/assets/cross-icon.svg";
import { Popup } from "../../../ui-kit/popup";
import { useGetMinAmountOfDeposit } from "../../../request/queries/wallet/getMinAmountOfDeposit";
import { useUser } from "../../../hooks/user-hook";
import { useDepositAddress } from "../../../request/queries/wallet/depositAddress";
import { Image } from "../../../ui-kit/image";
import { Skeleton } from "../../../сomponents/skeleton";
import { useDebounced } from "../../../hooks/debounced";
import { useGetEstimatePrice } from "../../../request/queries/wallet/getEstimatePrice";
import { useGetValidateAddressWithdrawal } from "../../../request/queries/wallet/getValidateAddressWithdrawal";
import { useGetFeeWithdraw } from "../../../request/queries/wallet/getFeeWithdraw";
import { usePreWithdrawal } from "../../../request/queries/wallet/preWithdrawal";
import { PinCodeInput } from "../../../сomponents/pin-code-input";
import { useVerifyWithdraw } from "../../../request/queries/wallet/sendVerifyWithdraw";
import { Translate } from "../../../ui-kit/translate";
import { useTranslation } from "../../../ui-kit/translate/translate";
import { useNavigate } from "react-router";
import { useWalletHistory } from "../wallet-history/wallet-history.props";

export type WalletViewProps = {};
export const WalletView: FC<WalletViewProps> = memo(() => {
  const [state, setState] = useState<TabType>(TabType.LEFT);
  const onTabClick = (tabState: TabType) => setState(tabState);

  const [currency, setCurrency] = useState<Currency>(Currency.BTC);
  const [network, setNetwork] = useState<NetworkCurrency>(NetworkCurrency.BTC);

  const setNewCurrency = (value: string) => {
    setCurrency(value as Currency);
    switch(value){

    }
    setCurrencyModal(false);
  };

  const { authToken, getUSDTBalance, user } = useUser();

  const { data: minAmount } = useGetMinAmountOfDeposit({
    authToken: authToken,
    currency_from: currency,
  });

  const setNewNetwork = (value: string) => {
    setNetwork(value as NetworkCurrency);
  };

  const [amount, setAmount] = useState<string>("");

  const [amountUSDT, setAmountUSDT] = useState<string>("");
  const [withdrawal, setWithdrawal] = useState<string>("");

  const debouncedValue = useDebounced(amountUSDT, 500);

  const { data: estimate } = useGetEstimatePrice({
    authToken,
    amountUSD: amountUSDT,
    amount: debouncedValue,
    currencyFrom: 
    "usd",
      currencyTo: networkTags[network] === "ERC20"
      ? "usdterc20"
      : networkTags[network] === "TRC20"
      ? "USDTTRC20"
      : networkTags[network],
  });

  const { data: depositInfo, isFetched } = useDepositAddress({
    amount: estimate?.estimated_amount.toString(),
    amountUSD: amountUSDT,
    authToken,
    currency:
      networkTags[network] === "ERC20"
        ? "usdterc20"
        : networkTags[network] === "TRC20"
        ? "USDTTRC20"
        : networkTags[network],
  });

  const [currencyModal, setCurrencyModal] = useState<boolean>(false);
  const [networkModal, setNetworkModal] = useState<boolean>(false);

  const debouncedAddress = useDebounced(withdrawal, 500);
  const debouncedAmount = useDebounced(amount, 500);

  const { data: validateWithdrawal } = useGetValidateAddressWithdrawal({
    authToken,
    address: debouncedAddress,
    currency: currency,
  });

  const [validWithdrawal, setValidWithdrawal] = useState<boolean>(false);

  useEffect(() => {
    if (!validateWithdrawal) {
      setValidWithdrawal(false);
      return;
    }

    setValidWithdrawal(true);
  }, [validateWithdrawal]);

  const { data: fee } = useGetFeeWithdraw({
    authToken,
    amount: debouncedAmount,
    currency,
  });

  const { mutate } = usePreWithdrawal({
    amount,
    currency:
      networkTags[network] === "ERC20"
        ? "usdterc20"
        : networkTags[network] === "TRC20"
        ? "USDTTRC20"
        : networkTags[network],
    address: withdrawal,
    authToken,
  });

  const [sendModal, setSendModal] = useState<boolean>(false);

  const [code, setCode] = useState<
    [string, string, string, string, string, string]
  >(["", "", "", "", "", ""]);
  const changeCode = useCallback(
    (value: [string, string, string, string, string, string]) => {
      setCode(value);
    },
    []
  );

  const clearCode = useCallback(() => {
    setSendModal(false);
    setErrorVerifyMessage(null);
    setCode(["", "", "", "", "", ""]);
  }, []);

  const { mutateAsync: verifyWithdrawal } = useVerifyWithdraw({
    authToken,
    code: code.join(""),
  });
  const [errorVerifyMessage, setErrorVerifyMessage] = useState<string | null>(null);

  const onVerify = async () => {
    try {
      await verifyWithdrawal({ code: Number(code.join()) });
      setSendModal(false);
      clearCode();
      setErrorVerifyMessage(null);
    } catch (err: any) {
      setErrorVerifyMessage(err.response.data.message);
    }
  };


  const [validate, setValidate] = useState<{
    amount: boolean;
    amountUsd: boolean;
  }>({
    amount: true,
    amountUsd: true,
  });

  const changeValidate = (type: "amount" | "amountUsd", valid: boolean) => {
    setValidate((prev) => {
      return { ...prev, [type]: valid };
    });
  };

  const confirm = useCallback(() => {
    mutate();
    setSendModal(true);
  }, [mutate]);

  const tabView = {
    left: (
      <DepositInfo
        translateAmount={estimate?.estimated_amount.toString() || ""}
        address={depositInfo?.address}
        qr={depositInfo?.qr}
        amount={amountUSDT}
        isPending={!isFetched}
        setInputMoney={setAmountUSDT}
        min={minAmount?.fiat_equivalent || 0}
        validate={validate.amount}
        changeValidate={changeValidate}
      />
    ),
    right: (
      <WithdrawalInfo
        amount={amount}
        setAmount={setAmount}
        withdrawal={withdrawal}
        setWithdrawal={setWithdrawal}
        fee={fee?.fee || "0"}
        validAddress={validWithdrawal}
        confirm={confirm}
        validate={validate.amountUsd}
        min={minAmount?.fiat_equivalent || 0}
        changeValidate={changeValidate}
      />
    ),
  };

  const translate = useTranslation();

  return (
    <Column height="100vh" padding={[30, 16, 0, 16]} horizontalAlign="center">
      <Blur />
      <Text themeFont={themes.fonts.h2} themeColor={themes.colors.foreground}>
        <Translate keyWord="wallet.wallet" />
      </Text>
      <Spacing variant="Column" themeSpace={25} />
      <Tab
        state={state}
        values={{
          left: { text: "Deposit", onClick: () => onTabClick(TabType.LEFT) },
          right: {
            text: "Withdrawal",
            onClick: () => onTabClick(TabType.RIGHT),
          },
        }}
      />
      <ScrollContainer>
        <Spacing variant="Column" themeSpace={25} />
        <Balance
          pnlPercent={user.pnlPercent ?? 0}
          pnlUSDT={user.pnlUsdt ?? 0}
          balance={getUSDTBalance}
        />
        <Spacing variant="Column" themeSpace={25} />
        <SelectCurrency
          label={`${translate("wallet.deposit.currency")}:`}
          value={currency.toString()}
          onClick={() => setCurrencyModal(true)}
        />
        <Spacing variant="Column" themeSpace={20} />
        <SelectCurrency
          label={`${translate("wallet.choose.network")}:`}
          value={networkTags[network]}
          onClick={() => setNetworkModal(true)}
        />
        <Spacing variant="Column" themeSpace={20} />
        {tabView[state]}
      </ScrollContainer>
      <Modals
        currency={currency}
        setNewCurrency={setNewCurrency}
        setNewNetwork={setNewNetwork}
        errorVerifyMessage={errorVerifyMessage}
        currencyActive={currencyModal}
        networkCurrency={networkModal}
        closeNetwork={() => setNetworkModal(false)}
        closeCurrency={() => setCurrencyModal(false)}
        send={sendModal}
        closeSendModal={clearCode}
        code={code}
        onVerify={onVerify}
        setCode={changeCode}
      />
    </Column>
  );
});

type BalanceProps = {
  balance: number;
  pnlPercent: number;
  pnlUSDT: number;
};
const Balance: FC<BalanceProps> = memo(({ balance, pnlPercent, pnlUSDT }) => {
  const goToWalletHistory = useWalletHistory();


  return (
    <SurfaceWallet padding={[15, 15]} radius={20} height={160} width="100%">
      <Text themeFont={themes.fonts.ht1} themeColor={themes.colors.gray}>
        <Translate keyWord="wallet.balance" />
      </Text>
      <Spacing variant="Column" themeSpace={20} />
      <Row
        horizontalAlign="space-between"
        style={{ width: "100%" }}
        verticalAlign="center"
      >
        <Text themeFont={themes.fonts.h1} themeColor={themes.colors.foreground}>
          ${`${balance}`}
        </Text>
        <Column verticalAlign="center" horizontalAlign="flex-end">
          <Row>
            <Text
              themeFont={themes.fonts.t}
              themeColor={themes.colors.foreground}
            >
              {`${pnlPercent}%`}
            </Text>
          </Row>
          <Row>
            <Text
              themeFont={themes.fonts.t}
              themeColor={themes.colors.foreground}
            >
              PnL
            </Text>
            <Spacing variant="Row" themeSpace={3} />
            <Text themeFont={themes.fonts.t} themeColor={themes.colors.primary}>
              {`${pnlUSDT}`} USDT
            </Text>
          </Row>
        </Column>
      </Row>
      <Spacing variant="Column" themeSpace={25} />
      <Column horizontalAlign="center">
        <Button width="100%" height={40} onClick={() => goToWalletHistory()}>
          <Text themeFont={themes.fonts.h3} themeColor="#000000">
            <Translate keyWord="wallet.history.open" />
          </Text>
        </Button>
      </Column>
    </SurfaceWallet>
  );
});

type SelectCurrencyProps = {
  label: string;
  value: string;
  onClick: () => void;
};

const SelectCurrency: FC<SelectCurrencyProps> = memo(
  ({ label, value, onClick }) => {
    return (
      <>
        <Column horizontalAlign="flex-start">
          <Text themeFont={themes.fonts.ht2} themeColor={themes.colors.gray}>
            {label}
          </Text>
        </Column>
        <Spacing variant="Column" themeSpace={6} />
        <Pressable onClick={onClick} style={{ width: "100%" }}>
          <Surface
            padding={[0, 10, 0, 15]}
            themeColorBorder="#4B4166"
            themeColor="transparent"
            width="100%"
            height={40}
          >
            <Row
              verticalAlign="center"
              horizontalAlign="space-between"
              style={{ width: "100%", height: "100%" }}
            >
              <Text
                themeColor={themes.colors.gray}
                themeFont={themes.fonts.ht2}
              >
                {value.toString()}
              </Text>
              <Icon size={20} themeColor="#BEBCC0" src={arrow} />
            </Row>
          </Surface>
        </Pressable>
      </>
    );
  }
);

type InputWalletProps = {
  value: string;
  onChange?: React.Dispatch<React.SetStateAction<string>>;
  warning?: string;
  isClear?: boolean;
  label: string;
  disabled?: boolean;
  validate?: boolean;
  onBlur?: () => void;
};

const InputWallet: FC<InputWalletProps> = memo(
  ({
    value,
    onChange,
    warning,
    isClear,
    label,
    disabled,
    validate = true,
    onBlur,
  }) => {
    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e.target.value);
    };

    const onRemove = () => {
      onChange && onChange("");
    };

    return (
      <>
        <Column horizontalAlign="flex-start">
          <Text themeFont={themes.fonts.ht2} themeColor={themes.colors.gray}>
            {label}
          </Text>
        </Column>
        <Spacing variant="Column" themeSpace={6} />
        <Row
          style={{ width: "100%", position: "relative" }}
          verticalAlign="center"
          horizontalAlign="center"
        >
          <StyledInput
            {...(onBlur && {
              onBlur: onBlur,
            })}
            validate={validate}
            disabled={disabled}
            value={value}
            onChange={onChangeInput}
          />
          {!!isClear && (
            <Pressable
              style={{ position: "absolute", right: 10 }}
              onClick={onRemove}
            >
              <Icon size={20} themeColor="#BEBCC0" src={close} />
            </Pressable>
          )}
        </Row>
        {!!warning && (
          <Column verticalAlign="center" horizontalAlign="flex-start">
            <Spacing variant="Column" themeSpace={6} />
            <Row verticalAlign="center">
              <Icon
                size={11}
                themeColor={validate ? "#BEBCC0" : themes.colors.error}
                src={warningSvg}
              />
              <Spacing variant="Row" themeSpace={4} />
              <Text
                themeFont={themes.fonts.ht2}
                themeColor={
                  validate === false ? themes.colors.error : themes.colors.gray
                }
              >
                {warning}
              </Text>
            </Row>
          </Column>
        )}
      </>
    );
  }
);

type WithdrawalInfoProps = {
  withdrawal: string;
  setWithdrawal: React.Dispatch<React.SetStateAction<string>>;
  amount: string;
  fee: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  validAddress: boolean;
  confirm: () => void;
  validate: boolean;
  changeValidate: (type: "amount" | "amountUsd", valid: boolean) => void;
  min: number;
};
const WithdrawalInfo: FC<WithdrawalInfoProps> = memo(
  ({
    withdrawal,
    setWithdrawal,
    amount,
    setAmount,
    fee,
    validAddress,
    confirm,
    validate,
    changeValidate,
    min,
  }) => {
    const onBlur = () => {
      console.log(Number(amount) > min);
      changeValidate("amountUsd", Number(amount) > min);
    };
    const translate = useTranslation();

    return (
      <>
        <InputWallet
          label={`${translate("wallet.enter.amount")}:`}
          value={amount}
          onChange={setAmount}
          warning={`Minimal ${min}`}
          validate={validate}
          onBlur={onBlur}
          isClear
        />
        <Spacing variant="Column" themeSpace={20} />
        <InputWallet
          label={`${translate("wallet.enter.withdrawal.address")}:`}
          value={withdrawal}
          onChange={setWithdrawal}
        />
        <Spacing variant="Column" themeSpace={20} />
        <Surface
          padding={[14, 14]}
          width="100%"
          themeColorBorder="#4B4166"
          themeColor="transparent"
        >
          <Column verticalAlign="center" horizontalAlign="center">
            <Row
              horizontalAlign="space-between"
              style={{ width: "100%" }}
              verticalAlign="center"
            >
              <Text
                themeFont={themes.fonts.ht2}
                themeColor={themes.colors.foreground}
              >
                <Translate keyWord="wallet.withdrawal.amount" />
              </Text>
              <Text
                themeFont={themes.fonts.h5}
                themeColor={themes.colors.foreground}
              >
                {amount} USDT
              </Text>
            </Row>
            <Spacing variant="Column" themeSpace={10} />
            <Row
              horizontalAlign="space-between"
              style={{ width: "100%" }}
              verticalAlign="center"
            >
              <Text
                themeFont={themes.fonts.ht2}
                themeColor={themes.colors.foreground}
              >
                <Translate keyWord="wallet.fee" />:
              </Text>
              <Text
                themeFont={themes.fonts.h5}
                themeColor={themes.colors.foreground}
              >
                {fee} USDT
              </Text>
            </Row>
            <Spacing variant="Column" themeSpace={10} />
            <Row
              horizontalAlign="space-between"
              style={{ width: "100%" }}
              verticalAlign="center"
            >
              <Text
                themeFont={themes.fonts.ht2}
                themeColor={themes.colors.foreground}
              >
                <Translate keyWord="wallet.total.withdrawal.amount" />:
              </Text>
              <Text
                themeFont={themes.fonts.h5}
                themeColor={themes.colors.primary}
              >
                {Number(amount) + Number(fee)} USDT
              </Text>
            </Row>
          </Column>
        </Surface>
        <Spacing variant="Column" themeSpace={20} />
        <Button
          disable={!validAddress || !validate}
          width="100%"
          height={40}
          onClick={confirm}
        >
          <Text themeColor={themes.colors.surface} themeFont={themes.fonts.ht1}>
            <Translate keyWord="wallet.confirm" />
          </Text>
        </Button>
      </>
    );
  }
);

type DepositInfoProps = {
  setInputMoney: React.Dispatch<React.SetStateAction<string>>;
  amount: string;
  address?: string;
  qr?: string;
  min: number;
  isPending: boolean;
  translateAmount: string;
  validate: boolean;
  changeValidate: (type: "amount" | "amountUsd", valid: boolean) => void;
};

const DepositInfo: FC<DepositInfoProps> = memo(
  ({
    setInputMoney,
    amount,
    address,
    qr,
    min,
    isPending,
    translateAmount,
    validate,
    changeValidate,
  }) => {
    const [moneys, setMoneys] = useState<{ value: number; state: boolean }[]>([
      { value: 10, state: false },
      { value: 20, state: false },
      { value: 50, state: false },
      { value: 100, state: false },
    ]);

    const onSelectMoney = (value: number) => {
      setMoneys((prev) =>
        prev.map((item) =>
          item.value === value
            ? { ...item, state: true }
            : { ...item, state: false }
        )
      );
      setInputMoney(value.toString());
    };

    const onBlur = () => {
      console.log(Number(amount) > min);
      changeValidate("amount", Number(amount) > min);
    };

    const translate = useTranslation();

    return (
      <>
        <InputWallet
          label={`${translate("wallet.enter.amount")}. USD`}
          value={amount}
          validate={validate}
          onChange={setInputMoney}
          {...(min >= Number(amount) && {
            warning: `Minimal $${min}`,
          })}
          onBlur={onBlur}
          isClear
        />
        {min < Number(amount) && (
          <>
            <Spacing variant="Column" themeSpace={6} />
            <Text
              themeFont={themes.fonts.ht2}
              themeColor={themes.colors.primary}
            >
              {translateAmount}
            </Text>
          </>
        )}
        <Spacing variant="Column" themeSpace={20} />
        <Row
          style={{ width: "100%" }}
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          {moneys.map((item, index) => (
            <Pressable onClick={() => onSelectMoney(item.value)} key={index}>
              <Surface
                radius={15}
                width={65}
                height={40}
                {...(item.state && {
                  themeColor: themes.colors.secondary,
                  themeColorBorder: themes.colors.secondary,
                })}
              >
                <Text
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  themeColor={item.state ? "#333333" : themes.colors.gray}
                  themeFont={themes.fonts.h4}
                >
                  ${item.value}
                </Text>
              </Surface>
            </Pressable>
          ))}
        </Row>
        <Spacing variant="Column" themeSpace={20} />
        {min < Number(amount) && (
          <>
            <Column horizontalAlign="center" verticalAlign="center">
              <Skeleton
                height="130px"
                width="130px"
                isSkeleton={!qr || isPending}
                children={<Image src={qr || ""} size={130} />}
              />
            </Column>
            <Spacing variant="Column" themeSpace={20} />
            <InputWallet
              label={`${translate("wallet.address")}:`}
              disabled
              value={address || ""}
            />
          </>
        )}
      </>
    );
  }
);

const Modals = ({
  currencyActive,
  currency,
  networkCurrency,
  closeCurrency,
  closeNetwork,
  setNewCurrency,
  errorVerifyMessage,
  setNewNetwork,
  send,
  closeSendModal,
  code,
  setCode,
  onVerify,
}: {
  closeCurrency: () => void;
  closeNetwork: () => void;
  currencyActive: boolean;
  networkCurrency: boolean;
  setNewCurrency: (value: string) => void;
  setNewNetwork: (value: string) => void;
  send: boolean;
  currency: Currency,
  closeSendModal: () => void;
  code: [string, string, string, string, string, string];
  setCode: (value: [string, string, string, string, string, string]) => void;
  onVerify: () => void;
  errorVerifyMessage: string | null;
}) => {
  const currencyArr = Object.values(Currency);

  const [networkArray, setNetworkArray] = useState<NetworkCurrency[]>([]);

  useEffect(() => {
    switch(currency){
      case Currency.BTC:
        setNetworkArray([NetworkCurrency.BTC]);
        setNewNetwork(NetworkCurrency.BTC);
        break;
      case Currency.ETH:
        setNetworkArray([NetworkCurrency.ETH]);
        setNewNetwork(NetworkCurrency.ETH);
        break;
      case Currency.LTC:
        setNetworkArray([NetworkCurrency.LTC]);
        setNewNetwork(NetworkCurrency.LTC);
        break;
      case Currency.DOGE:
        setNetworkArray([NetworkCurrency.DOGE]);
        setNewNetwork(NetworkCurrency.DOGE);
        break;
      case Currency.XRP:
        setNetworkArray([NetworkCurrency.XRP]);
        setNewNetwork(NetworkCurrency.XRP);
        break;
      case Currency.BCH:
        setNetworkArray([NetworkCurrency.BCH]);
        setNewNetwork(NetworkCurrency.BCH);
        break;
      case Currency.TON:
        setNetworkArray([NetworkCurrency.TON]);
        setNewNetwork(NetworkCurrency.TON);
        break;
      case Currency.SOL:
        setNetworkArray([NetworkCurrency.SOL]);
        setNewNetwork(NetworkCurrency.SOL);
        break;
      case Currency.USDT:
        setNetworkArray([NetworkCurrency.ERC20, NetworkCurrency.TRC20]);
        setNewNetwork(NetworkCurrency.TRC20);
        break;
      default:
        setNetworkArray([]);
        break;
    }
  },[currency, setNetworkArray, setNewNetwork])

  return (
    <>
      <Popup active={currencyActive} closePopup={closeCurrency}>
        <Column padding={[15, 15, 0, 15]} horizontalAlign="center">
          <Row
            style={{ width: "100" }}
            horizontalAlign="center"
            verticalAlign="center"
          >
            <Text themeFont={themes.fonts.h2}>
              <Translate keyWord="wallet.choose" />
            </Text>
            <Spacing variant="Row" themeSpace={10} />
            <Text
              themeColor={themes.colors.secondary}
              themeFont={themes.fonts.h2}
            >
              <Translate keyWord="wallet.currency" />
            </Text>
          </Row>
          <Spacing variant="Column" themeSpace={12} />
          {currencyArr.map((item, index) => (
            <div style={{ width: "100%" }} key={item + index}>
              <Pressable
                onClick={() => setNewCurrency(item)}
                style={{ width: "100%" }}
              >
                <Surface height={37} width="100%" key={item}>
                  <Column
                    style={{ height: "100%" }}
                    verticalAlign="center"
                    horizontalAlign="center"
                  >
                    <Text
                      themeFont={themes.fonts.h3}
                      themeColor={themes.colors.foreground}
                    >
                      {item}
                    </Text>
                  </Column>
                </Surface>
              </Pressable>
              <Spacing variant="Column" themeSpace={5} />
            </div>
          ))}
        </Column>
      </Popup>
      <Popup active={networkCurrency} closePopup={closeNetwork}>
        <Column padding={[15, 15, 0, 15]} horizontalAlign="center">
          <Row
            style={{ width: "100" }}
            horizontalAlign="center"
            verticalAlign="center"
          >
            <Text themeFont={themes.fonts.h2}>
              <Translate keyWord="wallet.network" />
            </Text>
            <Spacing variant="Row" themeSpace={10} />
            <Text
              themeColor={themes.colors.secondary}
              themeFont={themes.fonts.h2}
            >
              <Translate keyWord="wallet.currency" />
            </Text>
          </Row>
          <Spacing variant="Column" themeSpace={12} />
          {networkArray.map((item, index) => (
            <div style={{ width: "100%" }} key={item + index}>
              <Pressable
                onClick={() => {setNewNetwork(item); closeNetwork()}}
                style={{ width: "100%" }}
              >
                <Surface height={37} width="100%" key={item}>
                  <Column
                    style={{ height: "100%" }}
                    verticalAlign="center"
                    horizontalAlign="center"
                  >
                    <Text
                      themeFont={themes.fonts.h3}
                      themeColor={themes.colors.foreground}
                    >
                      {networkTags[item]}
                    </Text>
                  </Column>
                </Surface>
              </Pressable>
              <Spacing variant="Column" themeSpace={5} />
            </div>
          ))}
        </Column>
      </Popup>
      <Popup active={send} closePopup={closeSendModal}>
        <Column padding={[15, 15, 0, 15]} horizontalAlign="center">
          <Row
            style={{ width: "100" }}
            horizontalAlign="center"
            verticalAlign="center"
          >
            <Text themeFont={themes.fonts.h2}>
              <Translate keyWord="wallet.confirm" />
            </Text>
            <Spacing variant="Row" themeSpace={10} />
            <Text
              themeColor={themes.colors.secondary}
              themeFont={themes.fonts.h2}
            >
              <Translate keyWord="wallet.withdrawal" />
            </Text>
          </Row>
          <Spacing variant="Column" themeSpace={12} />
          <Text
            align="center"
            style={{ width: "265px" }}
            themeFont={themes.fonts.ht2}
          >
            <Translate keyWord="wallet.verify.text" />
          </Text>
          <Spacing variant="Column" themeSpace={12} />
          <PinCodeInput
            style={{ zIndex: 10 }}
            isError={errorVerifyMessage !== null}
            digits={code}
            setDigits={setCode}
          />
          <Spacing variant="Column" themeSpace={5} />
          <Column horizontalAlign='flex-start' style={{height: 10}}>
            <Text
                themeColor={themes.colors.error}
                themeFont={themes.fonts.ht2}
              >
                {errorVerifyMessage}
              </Text>
            </Column>
          <Spacing variant="Column" themeSpace={24} />
          <Button width="100%" height={40} onClick={onVerify}>
            <Text
              themeColor={themes.colors.surface}
              themeFont={themes.fonts.ht1}
            >
              <Translate keyWord="wallet.confirm" />
            </Text>
          </Button>
        </Column>
      </Popup>
    </>
  );
};
