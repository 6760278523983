
import { FC, memo, ReactNode, useState } from 'react';
import { Column } from '../../../ui-kit/column';
import { Surface } from '../../../ui-kit/surface';
import { Text } from '../../../ui-kit/text';
import { Blur } from '../terms-and-conditions/terms-and-conditions.styled';
import { Spacing } from '../../../ui-kit/spacing';
import { WalletState } from '../../../store/reducers/personal-account/wallet-slice';
import { Row } from '../../../ui-kit/row';
import { Line } from '../referral/referral.styled';
import { ScrollView } from '../../../ui-kit/scroll-view';
import { themes } from "../../../themes/theme";
import { Translate } from '../../../ui-kit/translate';

export type WalletHistoryViewProps = {
  walletState: WalletState;
};
export const WalletHistoryView: FC<WalletHistoryViewProps> = memo(({
  walletState
}) => {

  return (
    <>
      <Column padding={25} height="100vh" horizontalAlign="center">
        <Blur />
        <Text themeFont={themes.fonts.h2} themeColor={themes.colors.foreground}>
          <Translate keyWord="wallet.wallet" />
        </Text>
        <Spacing variant="Column" themeSpace={25} />
        <Surface radius={20}>
          <Column padding={25} horizontalAlign='center'>
            <ScrollView style={{maxHeight: 400}}>
            {
              walletState.wallet.history.map((item,index) => <>
                <Text  themeColor={themes.colors.primary} themeFont={themes.fonts.ht2}>
                {item.date}
                </Text>
                <Spacing variant='Column' themeSpace={5}/>
                {
                    item.data.map((payment,index) => <>
                      <Text  themeColor={'#8A8A8A'} themeFont={themes.fonts.ht2}>
                          {payment.time}
                      </Text>
                      <Spacing variant='Column' themeSpace={3}/>
                      <Row style={{width: '100%'}}>
                          <Row style={{flex:1}}>
                              <Text transform='uppercase' themeFont={themes.fonts.ht2}>
                                  {payment.currencies}
                              </Text>
                          </Row>
                          <Row style={{flex:1}}>
                              <Text themeFont={themes.fonts.ht2}>
                                  {payment.type}
                              </Text>
                          </Row>
                          <Row style={{flex:1}} horizontalAlign='flex-end'>
                              <Text themeFont={themes.fonts.ht2}>
                                  {payment.status}
                              </Text>
                          </Row>
                          <Row style={{flex: 1.5}} horizontalAlign='flex-end'>
                              <Text themeFont={themes.fonts.ht2}>
                                  {payment.amount}
                              </Text>
                          </Row>
                      </Row>
                      <Spacing variant='Column' themeSpace={5}/>
                      {
                        item.data.length !== index + 1 &&
                        <>
                          <Line/>
                          <Spacing variant='Column' themeSpace={5}/>
                        </> 
                      }
                    </>)
                }
                <Spacing variant='Column' themeSpace={15}/>
                </>)}
                </ScrollView>
          </Column>
        </Surface>
      </Column>
    </>
  )
});
