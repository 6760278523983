import { FC, memo, useCallback, useEffect } from "react";
import { Column } from "../../../сomponents/column";
import { Wrapper } from "../../../сomponents/wrapper";
import { Spacing } from "../../../сomponents/spacing";
import { Text } from "../../../сomponents/text";
import { theme } from "../../../themes/theme";
import Logo from "../../../images/bitrate-logo-new.svg";
import { useForm } from "react-hook-form";
import { Input } from "../../../сomponents/input";
import { LineText } from "../../../сomponents/line-text";
import { Row } from "../../../сomponents/row";
import { LinkButton } from "../../../сomponents/link-button";
import { RectangleButton } from "../../../сomponents/rectangle-button";
import { RegistrationContainer } from "./registration-log-in.styles";
import { LogoStyled } from "../../../сomponents/logo";
import { useMediaQuery } from "react-responsive";
import { InputMessage } from "../../../сomponents/input-message";
import { Pressable } from "../../../сomponents/pressable";
import { UserTelegram } from "../../../store/reducers/auth-slice";

export type RegistrationLogInViewProps = {
    userName: string;
    password: string;
    error: string | null;
    loading: "loading" | "error" | "idle" | "success";
    onRegistrationCreate: () => void;
    onRegistrationForgotPasswordEmail: () => void;
    onLogin: (username: string, password: string) => void;
    setUserName: (username: string) => void;
    setPassword: (password: string) => void;
    onAuthByTelegram: (user: UserTelegram) => void;
    onAuthByGoogle: () => void;
};

export const RegistrationLogInView: FC<RegistrationLogInViewProps> = memo(({
    onLogin,
    onRegistrationCreate, loading,
    onRegistrationForgotPasswordEmail,
    setUserName,
    userName,
    password,
    error,
    setPassword,
    onAuthByTelegram, onAuthByGoogle
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError
    } = useForm({ mode: "onChange" });

    const isMobile = useMediaQuery({ maxWidth: theme.toMobileSize });
    const onError = useCallback(() => { if (error !== null) { setError("login", { message: error }) } }, [error, setError]);

    useEffect(() => { onError() }, [onError]);

    const onSubmit = useCallback(() => {
        onLogin(userName, password);
        onError();
    }, [onError, onLogin, password, userName]);

    return (
        <Wrapper>
            <Column horizontalAlign="center">
                <RegistrationContainer>
                    <Column horizontalAlign="center">
                        <Spacing themeSpace={70} variant="Column" />
                        <LogoStyled src={Logo} />
                        <Spacing variant="Column" themeSpace={160} themeMobileSpace={80} />
                        <Text transform="uppercase" themeFont={theme.fonts.b}>
                            welcome
                        </Text>
                        <Spacing variant="Column" themeSpace={45} />
                        <Column horizontalAlign="center">
                            <Input
                                register={register}
                                name="login"
                                required={true}
                                type="text"
                                value={userName}
                                setValue={setUserName}
                                typeButton="input"
                                placeholder="Email / Phone Number"
                                placeholderInput="Enter Email / Phone Number"
                                errorInput={errors["login"]?.message && <InputMessage themeColor={theme.colors.error} text={errors["login"].message.toString()} />}
                            />
                            <Spacing variant="Column" themeMobileSpace={15} themeSpace={35} />
                            <Input
                                register={register}
                                name="password"
                                required={true}
                                type="password"
                                typeButton="password"
                                placeholder="Password"
                                placeholderInput="Enter Password"
                                value={password}
                                setValue={setPassword}
                            />
                        </Column>
                        <Spacing themeMobileSpace={10} themeSpace={15} variant="Column" />
                        <Row style={{ width: '100%' }} horizontalAlign="flex-end">
                            <LinkButton onClick={onRegistrationForgotPasswordEmail} themeColor={theme.colors.surfaceGray} text="Forgot Password?" />
                        </Row>
                        <Spacing variant="Column" themeMobileSpace={15} themeSpace={35} />
                        <LineText />
                        <Spacing variant="Column" themeMobileSpace={15} themeSpace={35} />
                        <Row>
                            {/* <CircleButton onClick={onAuthByGoogle} size={isMobile ? 40 : 60} imageSrc={google} />
                            <Spacing variant="Row" themeSpace={30} /> */}
                            
                        </Row>
                        <Spacing variant="Column" themeSpace={35} />
                        <Column horizontalAlign="center">
                            <RectangleButton state={loading} width={isMobile ? "100%" : 420} height={isMobile ? 40 : 60} onClick={handleSubmit(onSubmit)} variant="primary">
                                <Text themeColor={theme.colors.background} transform="uppercase" themeFont={theme.fonts.ht1}>
                                    Continue
                                </Text>
                            </RectangleButton>
                        </Column>
                        <Spacing variant="Column" themeSpace={15} />
                        <Pressable onClick={onRegistrationCreate}>
                            <Text themeFont={theme.fonts.t} themeColor={theme.colors.surfaceGray}>DON`T HAVE AN ACCOUNT? TIME TO <span style={{ cursor: "pointer", color: theme.colors.primary }}>CREATE</span></Text>
                        </Pressable>
                        <Spacing variant="Column" themeSpace={40} />
                        <Text themeColor={theme.colors.surfaceGray} themeFont={theme.fonts.t}>
                            © 2023-2024 BITRATE. All rights reserved
                        </Text>
                        <Spacing variant="Column" themeMobileSpace={10} themeSpace={10} />
                    </Column>
                </RegistrationContainer>
            </Column>
        </Wrapper>
    );
})