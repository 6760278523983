
import { FC, memo } from 'react';

import { SwiperFortuneProps } from './swiper-fortune.props';
import { SwiperFortuneView } from './swiper-fortune.view';
import { useSwiperFortune } from '../../../../hooks/swiper/swiper-fortune';

export const SwiperFortune: FC<SwiperFortuneProps> = memo(({isShow = false,closeFortune}) => {

  const {fortuneState, spinWheel, buySpinWheel, isSpinning, updateData} = useSwiperFortune();
  
  return (
    <SwiperFortuneView
      win={fortuneState.win}
      currentAngle={fortuneState.currentAngle}
      closeFortune={closeFortune}
      isSpinning={isSpinning}
      spinWheel={spinWheel}
      updateData={updateData}
      onBuy={buySpinWheel}
      isShow={isShow}
      segments={fortuneState.segments}
      countOfSpin={fortuneState.countOfSpins}
      buyIn={fortuneState.currentBuyInSpin?.cost || 0}/>
  );
});
