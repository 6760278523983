
import { FC, memo, useCallback, useState } from 'react';
import { Modal } from '../../../../ui-kit/modal';
import { ArrowWrapper, RingFortuneWhile, WrapperFortuneWhile, WrapperSegment, WrapperWhileFooter } from './swiper-fortune.styled';
import { Text } from '../../../../ui-kit/text';
import { theme, themes } from '../../../../ui-kit/themes/theme';
import { Surface } from '../../../../ui-kit/surface';
import { Column } from '../../../../ui-kit/column';
import { Spacing } from '../../../../ui-kit/spacing';
import { Row } from '../../../../ui-kit/row';
import { Button } from '../../../../ui-kit/button';
import { SegmentState } from '../../../../store/reducers/swiper/swiper-wheel';
import { Translate } from '../../../../ui-kit/translate';
import { Popup } from '../../../../ui-kit/popup';


import bitcoinSVG from '../../../../images/wins/bitcoin.svg';
import btrtSVG from '../../../../images/wins/btrt.svg';
import levelSVG from '../../../../images/wins/level.svg';
import moneySVG from '../../../../images/wins/money.svg';
import ticketSVG from '../../../../images/wins/ticket.svg';
import nftSVG from '../../../../images/wins/nft.svg';
import { Image } from '../../../../ui-kit/image';

export type SwiperFortuneViewProps = {
  countOfSpin: number;
  buyIn?: number;
  isShow: boolean;
  updateData: () => void;
  currentAngle: number;
  win: null | SegmentState;
  spinWheel: (onSuccess?: () => void) => void;
  onBuy: () => void;
  closeFortune: () => void;
  isSpinning: boolean;
  segments: SegmentState[];
};
export const SwiperFortuneView: FC<SwiperFortuneViewProps> = memo(({ 
  segments, 
  currentAngle, 
  spinWheel, 
  isShow, 
  win,
  closeFortune, 
  countOfSpin, 
  updateData,
  isSpinning,
  buyIn = 100, onBuy 
}) => {
  const [isShowPrize, setIsShowPrize] = useState<boolean>(false);

  const triggerShowPrize = useCallback(() => {
    setTimeout(() => {
      setIsShowPrize(true);
    }, 15000);
  }, []);

  const onClickSpin = useCallback(() => {
    spinWheel(triggerShowPrize);
  },[spinWheel, triggerShowPrize])

  return (
    <>
    <Modal 
      padding={[20,0,0,0]} 
      style={{height: '485px', overflow: 'hidden',background:'#1C1A22'}} 
      isActive={isShow} closePopup={closeFortune}>
      <Column horizontalAlign='center'>
        <Text themeFont={themes.fonts.h2} themeColor={themes.colors.surface}>
          <span style={{color: themes.colors.primary}}><Translate keyWord='fortune.title.part1' /></span> <Translate keyWord='fortune.title.part2' />
        </Text>
        <Spacing variant='Column' themeSpace={20}/>
        <SpinsPanel count={countOfSpin}/>
        <Spacing variant='Column' themeSpace={30}/>
        <RingFortuneWhile size={363}>
          <ArrowWhileView/>
          <RingFortuneWhile size={305}>
            <WrapperFortuneWhile>
              <WrapperSegment currentAngle={currentAngle}>
                {
                  segments.map(item => <SegmentView text={item.name}/>)
                }
              </WrapperSegment>
              <Button 
                disable={isSpinning || countOfSpin === 0}
                onClick={onClickSpin}
                variant='secondary'
                width={96} height={96} 
                radius={48}
                style={{
                  boxShadow: countOfSpin === 0 ? 'none' : '5px 10px 40px 10px rgba(183, 94, 213, 0.25)',
                  cursor: 'pointer',
                  backgroundColor: countOfSpin === 0 ? '#6b618d' : themes.colors.secondary,
                  zIndex: 10000
                }}>
                  <Text themeFont={themes.fonts.h2} themeColor={'#1C1A22'}>
                    <Translate keyWord='fortune.spin.button' />
                  </Text>
              </Button>
            </WrapperFortuneWhile>
          </RingFortuneWhile>
        </RingFortuneWhile>
        <WrapperWhileFooter>
          <Spacing variant='Column' themeSpace={50}/>
          <Text style={{fontWeight: 400}} align='center' themeFont={themes.fonts.ht1} themeColor={themes.colors.surface}>
            <Translate keyWord='fortune.more.part1' />
            <br/>
            <Translate keyWord='fortune.more.part2' />
          </Text>
          <Spacing variant='Column' themeSpace={15}/>
          <Button width={130} onClick={onBuy} height={40}>
            <Column style={{height: '100%'}} horizontalAlign='center' verticalAlign='center'>
              <Text themeFont={themes.fonts.h4} themeColor={'#1c1c1c'}>
                {buyIn} BTRT
              </Text>
            </Column>
          </Button>
        </WrapperWhileFooter>
      </Column>
    </Modal>
    <Popup zIndex={10000} padding={[40, 15, 35, 15]} showCloseButton={true} active={isShowPrize} closePopup={() => setIsShowPrize(false)}>
      <Column horizontalAlign='center' verticalAlign='center' style={{height: '100%'}}>
        {win?.name.includes('BTC') && <Image style={{width: 'auto', height: 'auto'}} src={bitcoinSVG}/>}
        {win?.name.includes('Ticket') && <Image style={{width: 'auto', height: 'auto'}} src={ticketSVG}/>}
        {win?.name.includes('BTRT') && <Image style={{width: 'auto', height: 'auto'}} src={btrtSVG}/>}
        {win?.name.includes('USDT') && <Image style={{width: 'auto', height: 'auto'}} src={moneySVG}/>}
        {win?.name.includes('LEVEL') && <Image style={{width: 'auto', height: 'auto'}} src={levelSVG}/>}
        {win?.name.includes('NFT') && <Image style={{width: 'auto', height: 'auto'}} src={nftSVG}/>}
        <Spacing themeSpace={20} variant='Column' />
        <Text themeFont={themes.fonts.t} style={{fontSize: 16}}>
          You’ve got a <span style={{color: themes.colors.primary}}>prize!</span>
        </Text>
        <Spacing themeSpace={10} variant='Column' />
        <Button width={270} height={40} onClick={() => {setIsShowPrize(false); updateData()}} variant='primary'>
          <Text themeColor={themes.colors.gray} themeFont={themes.fonts.h4}>
            Collect
          </Text>
        </Button>
      </Column>
    </Popup>
    </>
  );
});

export type SegmentViewProps = {
  text?: string;
};
export const SegmentView: FC<SegmentViewProps> = memo(({ text }) => {

  const words = text ? text.split(' ') : [];

  const offsetY = (words.length - 1) * -0.6;

  return (
    <div>
      <svg width="95" height="76" viewBox="0 0 95 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_7278_44770" fill="white">
        <path d="M0.949791 13.9606C-0.0498166 11.3865 1.22459 8.47951 3.83494 7.57863C32.1341 -2.18815 62.9307 -1.98514 91.0987 8.15388C93.6969 9.08911 94.9329 12.0126 93.8994 14.5734L70.626 72.2419C69.5925 74.8026 66.6831 76.0187 64.0441 75.2054C52.9668 71.7915 41.1245 71.7135 30.0032 74.981C27.3537 75.7594 24.4605 74.5051 23.461 71.9309L0.949791 13.9606Z"/>
        </mask>
        <path d="M0.949791 13.9606C-0.0498166 11.3865 1.22459 8.47951 3.83494 7.57863C32.1341 -2.18815 62.9307 -1.98514 91.0987 8.15388C93.6969 9.08911 94.9329 12.0126 93.8994 14.5734L70.626 72.2419C69.5925 74.8026 66.6831 76.0187 64.0441 75.2054C52.9668 71.7915 41.1245 71.7135 30.0032 74.981C27.3537 75.7594 24.4605 74.5051 23.461 71.9309L0.949791 13.9606Z" fill="#1E1F24" stroke="url(#paint0_linear_7278_44770)" stroke-opacity="0.2" stroke-width="4" mask="url(#path-1-inside-1_7278_44770)"/>
        <text style={{ fontFamily: themes.fonts.h4.family, fontSize: themes.fonts.h4.mobileSize, textAlign: 'center'}} x="50%" y="50%" text-anchor="middle" dominant-baseline="middle"  fill="white">
          {words.map((word, index) => (
            <tspan key={index} x="50%" dy={`${index === 0 ? offsetY : 1.2}em`}>{word}</tspan>
          ))}
        </text>
        <defs>
        <linearGradient id="paint0_linear_7278_44770" x1="-108.791" y1="232.93" x2="37.763" y2="-454.879" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BAA7FF"/>
        <stop offset="1" stop-color="#0DC6A0"/>
        </linearGradient>
        </defs>
      </svg>
    </div>
  );
});


export type ArrowWhileViewProps = {

};
export const ArrowWhileView: FC<SegmentViewProps> = memo(() => {
  return (
    <ArrowWrapper>
      <svg width="127" height="129" viewBox="0 0 127 129" preserveAspectRatio="xMidYMid meet" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_6980_38073)">
        <path d="M62.4453 72.4438L43.3928 32.6938L81.4979 32.6939L62.4453 72.4438Z" fill="#BAA7FF" shape-rendering="crispEdges"/>
        <path d="M60.6418 73.3083C60.9744 74.0023 61.6757 74.4438 62.4453 74.4438C63.2149 74.4438 63.9162 74.0023 64.2488 73.3083L83.3014 33.5583C83.5984 32.9386 83.5565 32.2098 83.1903 31.6282C82.8242 31.0467 82.1851 30.6939 81.4979 30.6939L43.3928 30.6938C42.7055 30.6938 42.0664 31.0467 41.7003 31.6282C41.3341 32.2098 41.2922 32.9386 41.5892 33.5583L60.6418 73.3083Z" stroke="url(#paint0_linear_6980_38073)" stroke-opacity="0.2" stroke-width="4" stroke-linejoin="round" shape-rendering="crispEdges"/>
        </g>
        <defs>
        <filter id="filter0_d_6980_38073" x="0.394531" y="0.693848" width="126.102" height="127.75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1" dy="12"/>
        <feGaussianBlur stdDeviation="20"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.729412 0 0 0 0 0.654902 0 0 0 0 1 0 0 0 0.6 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6980_38073"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6980_38073" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_6980_38073" x1="68.8391" y1="82.6022" x2="-39.8995" y2="23.4148" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BAA7FF"/>
        <stop offset="1" stop-color="#0DC6A0"/>
        </linearGradient>
        </defs>
      </svg>
    </ArrowWrapper>
  );
});

export type SpinsPanelProps = {
  count?: number;
};
export const SpinsPanel: FC<SpinsPanelProps> = memo(({ count = 0}) => {
  return (
    <Surface themeColor={'#1C1C1C'} themeColorBorder={themes.colors.lightSurface} radius={50} padding={0} width={'auto'}>
      <Row verticalAlign='center'>
        <Text style={{fontWeight: 400, padding: 9}} themeFont={themes.fonts.ht2} themeColor={themes.colors.gray}>
          <Translate keyWord='fortune.spins' />
        </Text>
        <Surface 
          themeColor={'#1C1C1C'} 
          themeColorBorder={themes.colors.lightSurface} 
          radius={50} padding={0} 
          width={30} height={30}>
          <Column style={{height: '100%'}} horizontalAlign='center' verticalAlign='center'>
            <Text themeFont={themes.fonts.h3} themeColor={themes.colors.primary}>
              {count}
            </Text>
          </Column>
        </Surface>
      </Row>
    </Surface>
  )
});