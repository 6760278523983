import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useTypedSelector } from "../use-typed-selector"
import { useUser } from "../user-hook";
import { buySpinAction, getAccessibleSpinsAction, initWheelDataAction, spinWheelAction, swiperWheelSlice } from "../../store/reducers/swiper/swiper-wheel";
import { current } from "@reduxjs/toolkit";
import { swiperFarmSlice } from "../../store/reducers/swiper/swiper-farm-slice";

export const useSwiperFortune = () => {
    const fortuneState = useTypedSelector(state => state.fortune);
    const { authToken, refreshUserBalance, getBTRTBalance } = useUser();
    const dispatch = useAppDispatch();

    const initDataFortune = useCallback(() => {
        dispatch(initWheelDataAction({ authToken: authToken }));
    }, [authToken, dispatch])

    const updateData = useCallback(() => {
        dispatch(initWheelDataAction({ authToken: authToken }));

        dispatch(swiperFarmSlice.actions.setAmountBtrt(getBTRTBalance));

        refreshUserBalance();
    }, [authToken, dispatch, refreshUserBalance, getBTRTBalance])

    const getAccessibleSpins = useCallback(() => {
        dispatch(getAccessibleSpinsAction({ authToken: authToken }));
    }, [authToken, dispatch])

    const getResultRotate = useMemo(() => (sector: number): number => {
        const resetAngle = 360 - fortuneState.currentAngle % 360;
        const randomWheels = Math.floor(Math.random() * (15 - 5 + 1)) + 5;

        const intervalSector = Math.floor(Math.random() * 31) - 15;

        const sectorAngles: { [key: number]: number } = {
            1: 330,
            2: 285,
            3: 240,
            4: 195,
            5: 150,
            6: 105,
            7: 60,
            8: 15
        };

        const sectorAngle = sectorAngles[sector] || 0;
    
        return fortuneState.currentAngle + resetAngle + randomWheels * 360 + sectorAngle + intervalSector;
    }, [fortuneState.currentAngle]); 

    const rotateWheel = useCallback((sector: number) => {
        const newAngle = getResultRotate(sector);
        dispatch(swiperWheelSlice.actions.setCurrentAngle(newAngle));
    }, [dispatch,getResultRotate]);

    const [isSpinning, setIsSpinning] = useState(false);

  const spinWheelWithDelay = useCallback(async (sector: number) => {
    setIsSpinning(true);

    rotateWheel(sector);

    await new Promise((resolve) => setTimeout(resolve, 15000));

    setIsSpinning(false);
  }, [rotateWheel]);

    const spinWheel = useCallback((onSuccess?: () => void) => {
        dispatch(spinWheelAction({ authToken: authToken, rotateWheel: spinWheelWithDelay, refreshUserBalance, onSuccess }));
    }, [dispatch, authToken, spinWheelWithDelay, refreshUserBalance]);

    const buySpinWheel = useCallback(() => {
        dispatch(buySpinAction({ 
            authToken: authToken, 
            refreshUserBalance: refreshUserBalance, 
            getAccessibleSpins: getAccessibleSpins,
            currentBuyInSpin: fortuneState.currentBuyInSpin }));
    }, [authToken, dispatch, getAccessibleSpins, refreshUserBalance, fortuneState.currentBuyInSpin]);

    return {
        fortuneState: fortuneState,
        spinWheel: spinWheel,
        isSpinning: isSpinning,
        buySpinWheel: buySpinWheel,
        initDataFortune: initDataFortune,
        getAccessibleSpins: getAccessibleSpins,
        updateData
    }
}