import { 
    createChart, 
    ColorType, 
    IChartApi, 
    LineStyle, 
    ISeriesApi, 
    UTCTimestamp, 
    AreaData 
} from 'lightweight-charts';
import { FC, useEffect, useRef, useState } from 'react';
import { theme } from '../../themes/theme';
import { CircleLoading } from '../circle-loading';
import { SSEStatus } from '../../api/chart-api';

export type ChartData = { timestamp: string, value: number }[];

type TradingViewAreaChartProps = {
    data: ChartData;
    statusChart: SSEStatus;
}

type ChartDataPoint = AreaData<UTCTimestamp>;

const TradingViewAreaChart: FC<TradingViewAreaChartProps> = ({ data, statusChart }) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartInstanceRef = useRef<IChartApi | null>(null);
    const areaSeriesRef = useRef<ISeriesApi<'Area'> | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    // Инициализация графика при монтировании компонента
    useEffect(() => {
        if (!chartContainerRef.current) return;

        // Создание графика
        chartInstanceRef.current = createChart(chartContainerRef.current, {
            crosshair: {
                vertLine: {
                    visible: false,
                },
                horzLine: {
                    visible: false,
                }
            },
            layout: {
                textColor: theme.colors.surface,
                background: { type: ColorType.Solid, color: 'transparent' }
            },
            grid: {
                vertLines: {
                    visible: false
                },
                horzLines: {
                    color: 'rgba(255, 255, 255, 0.2)',
                    style: LineStyle.Dashed,
                },
            },
            handleScale: true, // Оставляем масштабирование включённым
            handleScroll: true, // Разрешаем панорамирование
            rightPriceScale: {
                scaleMargins: {
                    top: 0.2,
                    bottom: 0.2, // Отступ снизу
                },
                ticksVisible: true,
                // Оставляем autoScale включённым, чтобы цена автоматически подстраивалась
                // autoScale: true,
            },
            timeScale: {
                timeVisible: true,
                secondsVisible: true,
                ticksVisible: true,
                barSpacing: 15,
                rightOffset: 0,
                fixRightEdge: true,
            },
            autoSize: true,
        });

        // Добавление серии данных один раз
        areaSeriesRef.current = chartInstanceRef.current.addAreaSeries({
            topColor: 'rgba(13, 198, 160, 0.4)',
            bottomColor: 'rgba(122, 186, 173, 0.4)',
            lineColor: 'rgba(13, 198, 160, 1)',
            lineWidth: 2,
        });

        // Установка начальных данных
        if (data.length) {
            const formattedData: ChartDataPoint[] = data.map(point => {
                const [datePart, timePart] = point.timestamp.split(" ");
                const [year, month, day] = datePart.split("-").map(Number);
                const [hours, minutes, seconds] = timePart.split(":").map(Number);
                const monthIndex = month - 1;

                const unixTime = Math.floor(new Date(Date.UTC(year, monthIndex, day, hours, minutes, seconds)).getTime() / 1000) as UTCTimestamp;

                return { time: unixTime, value: point.value };
            });

            const uniqueSortedData = Array.from(
                new Map(formattedData.map((item) => [item.time, item])).values()
            ).sort((a, b) => a.time - b.time);

            areaSeriesRef.current.setData(uniqueSortedData);
        }

        // Настройка временной шкалы
        const timeScale = chartInstanceRef.current.timeScale();
        timeScale.applyOptions({
            timeVisible: true,
            secondsVisible: true,
            ticksVisible: true,
            barSpacing: 15,
            rightOffset: 0,
            fixRightEdge: true,
        });

        // Подгонка графика по содержимому при инициализации
        timeScale.fitContent();

        // Обработчик изменения размера окна
        const handleResize = () => {
            if (chartInstanceRef.current && chartContainerRef.current) {
                chartInstanceRef.current.applyOptions({ width: chartContainerRef.current.clientWidth });
                chartInstanceRef.current.timeScale().fitContent(); // Можно убрать, если не нужно подгонять по содержимому
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            if (chartInstanceRef.current) {
                chartInstanceRef.current.remove();
                chartInstanceRef.current = null;
            }
        };
    }, []); // Пустой массив зависимостей для однократной инициализации

    // Обновление данных без пересоздания графика
    useEffect(() => {
        if (chartInstanceRef.current && areaSeriesRef.current && data.length) {
            const formattedData: ChartDataPoint[] = data.map(point => {
                const [datePart, timePart] = point.timestamp.split(" ");
                const [year, month, day] = datePart.split("-").map(Number);
                const [hours, minutes, seconds] = timePart.split(":").map(Number);
                const monthIndex = month - 1;

                const unixTime = Math.floor(new Date(Date.UTC(year, monthIndex, day, hours, minutes, seconds)).getTime() / 1000) as UTCTimestamp;

                return { time: unixTime, value: point.value };
            });

            const uniqueSortedData = Array.from(
                new Map(formattedData.map((item) => [item.time, item])).values()
            ).sort((a, b) => a.time - b.time);

            areaSeriesRef.current.setData(uniqueSortedData);
        }
    }, [data]);

    // Управление состоянием загрузки
    useEffect(() => {
        if (statusChart === "ready" && data.length > 0) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [statusChart, data]);
    

    return (
        <div
            ref={chartContainerRef}
            style={{
                width: '100%',
                height: '100%',
                padding: "10px 0px 0px 0px",
                position: 'relative',
            }}
        >
            {isLoading && <CircleLoading state={'loading'} />}
        </div>
    );
};

export default TradingViewAreaChart;
