import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FinishPayloadType, GameDataProps, GamePayloadType, SSEStatus, gameApi } from "../../../api/sit-go/game-api";
import { AppDispatch } from "../..";
import axios from "axios";
import { appStatusSlice } from "../app-status-slice";
import { getSitAndGoBTRTRooms, getSITGORooms } from "./rooms-slice";

export type UserGame = Omit<GameDataProps<FinishPayloadType | GamePayloadType>, 'payload'> | null;

type GameDataSliceProps = {
    usersGame: GameDataProps<FinishPayloadType | GamePayloadType> | null;
    loading: "idle" | "loading" | "error" | "success";
    bet: "idle" | "error" | "loading" | "success";
    nonValidate: boolean;
    nonAmountGame: boolean;
    status: SSEStatus;
    error: null | string;
    errorBet: null | string;
    betValue: string;
    currentBetValue: number;
    leave: boolean;
}

const initialState: GameDataSliceProps = {
    usersGame: null,
    loading: "idle",
    bet: "idle",
    error: null,
    errorBet: null,
    status: "pending",
    betValue: "",
    leave: false,
    nonValidate: false,
    currentBetValue: 0,
    nonAmountGame: false,
}

export const gameSlice = createSlice({
    name: 'game',
    initialState: initialState,
    reducers: {
        dataReceived(state, action: PayloadAction<GameDataProps<GamePayloadType | FinishPayloadType>>) {
            state.usersGame = action.payload;
        },
        dataClear(state) {
            state.usersGame = null;
            state.leave = false;
            state.loading = "idle";
            state.bet = "idle";
            state.betValue = "";
            state.currentBetValue = 0;
            state.error = null;
            state.errorBet = null;
            state.status = "pending";
            state.nonValidate = false;
            state.nonAmountGame = false;
        },
        setStatus(state, action: PayloadAction<SSEStatus>) {
            state.status = action.payload;
        },
        setBetValue(state, action: PayloadAction<string>) {
            state.betValue = action.payload;
        },
        fetchValidate(state, action: PayloadAction<boolean>) {
            state.nonValidate = action.payload;
        },
        fetchAmountGame(state, action: PayloadAction<boolean>) {
            state.nonAmountGame = action.payload;
        },
        clearAmountGame(state) {
            state.nonAmountGame = false;
        },
        clearValidate(state) {
            state.nonValidate = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSeatUserGame.fulfilled, (state, action: PayloadAction<SeatDataType>) => {
                state.loading = "success";
            })
            .addCase(fetchSeatUserGame.pending, (state) => {
                state.loading = "loading";
            })
            .addCase(fetchSeatUserGame.rejected, (state) => {
                state.loading = "error";
            })
            .addCase(fetchMakeBet.fulfilled, (state, action: PayloadAction<BetDataType>) => {
                state.bet = "success";
            })
            .addCase(fetchMakeBet.pending, (state) => {
                state.bet = "loading";
            })
            .addCase(fetchMakeBet.rejected, (state) => {
                state.bet = "loading";
                state.errorBet = "Seat is Error"
            })
            .addCase(fetchLeave.fulfilled, (state, action: PayloadAction<boolean>) => {
                state.leave = action.payload;
            })
            .addCase(fetchCurrentBet.fulfilled, (state, action: PayloadAction<number>) => {
                state.currentBetValue = action.payload;
                state.bet = "success"
            })
            .addCase(fetchCurrentBet.rejected, (state) => {
                state.bet = "error";
            })
    }
})

let _newDataHandler: ((data: GameDataProps<FinishPayloadType | GamePayloadType>) => void) | null = null;
const newDataHandlerCreator = (dispatch: AppDispatch) => {
    if (_newDataHandler === null) {
        _newDataHandler = (data) => {
            dispatch(gameSlice.actions.dataReceived(data))
        }
    }
    return _newDataHandler;
}

let _statusChangedHandler: ((status: SSEStatus) => void) | null = null;
const statusChangedHandlerCreator = (dispatch: AppDispatch) => {
    if (_statusChangedHandler === null) {
        _statusChangedHandler = (status) => {
            dispatch(gameSlice.actions.setStatus(status))
        }
    }
    return _statusChangedHandler;
}

export const startGameListening = (authToken: string, roomId: number, isEnter: boolean) => async (dispatch: AppDispatch) => {
    gameApi.start(authToken, roomId, isEnter);
    gameApi.subscribe("messages-received", newDataHandlerCreator(dispatch));
    gameApi.subscribe("status-changed", statusChangedHandlerCreator(dispatch));
}

export const stopGameListening = () => async (dispatch: AppDispatch) => {
    gameApi.unsubscribe("messages-received", newDataHandlerCreator(dispatch));
    gameApi.unsubscribe("status-changed", statusChangedHandlerCreator(dispatch));
    gameApi.stop();
}

export const fetchSeatUserGame = createAsyncThunk('game/seat',
    async (data: SeatDataType, thunkAPI) => {
        const { authToken, roomId, amount, currency } = data;
        try {
            const responceValidate = await gameApi.validate(authToken, amount, currency);
            if (responceValidate === true) {
                thunkAPI.dispatch(gameSlice.actions.fetchValidate(false));
                const responce = await gameApi.seat(authToken, roomId);
                if (axios.isAxiosError(responce)) {
                    //console.log(1)
                    thunkAPI.dispatch(gameSlice.actions.fetchAmountGame(true));
                }
                else {
                    return responce;
                }
            }
            else {
                thunkAPI.dispatch(gameSlice.actions.fetchValidate(true));
            }
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkAPI.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            }
        }
    });

export const fetchMakeBet = createAsyncThunk('game/make-bet',
    async (data: BetDataType, thunkAPI) => {
        const { roomId, amount, userName, bet, authToken } = data;
        try {
            const responce = await gameApi.makeBet(authToken, roomId, userName, bet, amount);
            return responce || 0;
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkAPI.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            }
        }
    }
);

export const fetchCurrentBet = createAsyncThunk('game/current-bet',
    async (data: { roomId: number, authToken: string }, thunkAPI) => {
        const responce = await gameApi.getCurrentBet(data.authToken, data.roomId).then((responce) => {
            if (responce === undefined)
                throw new Error();

            return responce;
        }).catch((e) => {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401) {
                    thunkAPI.dispatch(appStatusSlice.actions.setStatusApp({ status: "no-autorizate" }))
                }
            }
            return 0;
        })

        return responce
    }
);

type LeaveActionType = {
    authToken: string;
    roomId: number;
    currency: string;
}
export const fetchLeave = createAsyncThunk('game/leave',
    async (data: LeaveActionType, thunkAPI) => {
        const { authToken, roomId } = data;
        const leave = await gameApi.leave(authToken, roomId);

        if(axios.isAxiosError(leave)) thunkAPI.rejectWithValue(leave);

        thunkAPI.dispatch(getSITGORooms({ authToken: authToken, players: "TWO", time: "FIVE", filter: "ALL" }))
        thunkAPI.dispatch(getSitAndGoBTRTRooms({ authToken: authToken, players: "TWO", time: "FIVE", filter: "ALL" }))
        return leave;
    }
)

type SeatDataType = {
    authToken: string;
    roomId: number;
    amount: number;
    currency: string;
}

type BetDataType = {
    authToken: string;
    roomId: number;
    userName: string;
    bet: number;
    amount: number;
}

export default gameSlice.reducer;