
import { useNavigate } from 'react-router';

export type WalletHistoryProps = {};

export const useWalletHistory = () => {
    const navigate = useNavigate();
    const walletHistory = (props?: WalletHistoryProps) => {
        navigate('/wallet-history', { state: props });
    };

    return walletHistory;
};