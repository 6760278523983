import React, { FC, ReactNode, memo, useCallback, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { Provider } from 'react-redux';
import { setupStore } from './store';
import { createRoot } from 'react-dom/client';
import { SDKProvider, isTMA } from '@telegram-apps/sdk-react';
import AppTelegram from './AppTelegram';
import BitrateLogo from './images/bitrate-logo-new.svg';
import { useAppDispatch } from './hooks/use-typed-selector';
import { telegramSlice } from './store/reducers/telegram-slice';
import { LoadingScreen, StyledLogoLoading } from './ui-kit/loading-view/loading-view';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from './request/queries/queryClient';

const container = document.getElementById('app');
const root = createRoot(container!);

const store = setupStore();


const Inner: FC = memo(() => {
  return (
    <SDKProvider>
      <DisplayUserApp
        telegram={<AppTelegram />}
        web={<App />}
        loading={
          <LoadingScreen>
            <StyledLogoLoading src={BitrateLogo} />
          </LoadingScreen>
        }
      />
    </SDKProvider>
  );
});

export type DisplayApp = {
  loading: ReactNode;
  telegram: ReactNode;
  web: ReactNode;
}
export const DisplayUserApp: FC<DisplayApp> = memo(({ loading, telegram, web }) => {
  const [state, setState] = useState<"loading" | "Telegram" | "Web">("loading");
  const dispatch = useAppDispatch();

  const checkTMA = useCallback(() => {
    isTMA().then((responce: boolean) => {
      if (responce === true) {
        setState("Telegram");
        dispatch(telegramSlice.actions.isTelegram(true));
      }
      else {
        setState("Web");
        dispatch(telegramSlice.actions.isTelegram(false));
      }
    })
  }, [dispatch])

  useEffect(() => {
    checkTMA();
  }, [checkTMA])
  return (
    state === "loading" ? loading : state === "Web" ? web : telegram
  );
})
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Inner />
        </QueryClientProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);

reportWebVitals();
