import axios from 'axios';
import { EventSourcePolyfill, MessageEvent } from 'event-source-polyfill';


export type SSEStatus = "pending" | "reconnect" | "ready" | "error";

export type MessagesReceivedSubscriberProps = (data: GameDataProps<FinishPayloadType | GamePayloadType>) => void;
export type StatusChangedSubscriberProps = (status: SSEStatus) => void;

export type EventName = "messages-received" | "status-changed";


const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

const subscribers = {
    "messages-received": [] as MessagesReceivedSubscriberProps[],
    "status-changed": [] as StatusChangedSubscriberProps[],
}

let source: EventSourcePolyfill | null = null;
let reconectAmount = 0;

const dataHandler = (ev: MessageEvent) => {
    const newData: GameDataProps<FinishPayloadType | GamePayloadType> = JSON.parse(ev.data);
    reconectAmount = 0;
    //console.log(newData);
    subscribers["messages-received"].forEach(s => s(newData));
}

const errorHandler = (error: any) => {

    if ((error.status === 400 || error.status === 500 || error.status === 404) || reconectAmount === 3) {
        notifySubsribersAboutStatus("error");
        reconectAmount = 0;
        source?.close();
        console.log("error");
        return;
    }

    notifySubsribersAboutStatus("reconnect");
    console.log("reconnect", reconectAmount);
    reconectAmount++;
    source?.close();
}

const openHandler = () => {
    notifySubsribersAboutStatus("ready");
    console.log("ready")
}

const cleanUp = () => {
    source?.removeEventListener('error', errorHandler);
    source?.removeEventListener('message', dataHandler);
    source?.removeEventListener('open', openHandler);
}

const notifySubsribersAboutStatus = (status: SSEStatus) => {
    subscribers["status-changed"].forEach((s) => s(status));
}

function createGameChannel(authToken: string, roomId: number, isEnter: boolean) {
    cleanUp();
    source?.close();
    const type = isEnter === true ? 'connect' : 'enter';
    source = new EventSourcePolyfill(`${process.env.REACT_APP_API_URL}/game/${type}?roomId=${roomId}`, {
        headers: {
            Connection: 'keep-alive',
            Authorization: `Bearer ${authToken}`,
        },
        heartbeatTimeout: 144000000,
        withCredentials: true,
    });
    notifySubsribersAboutStatus("pending");
    source.addEventListener('error', errorHandler);
    source.addEventListener('message', dataHandler)
    source.addEventListener('open', openHandler);
}

export const gameApi = {
    start(authToken: string, roomId: number, isEnter: boolean) {
        createGameChannel(authToken, roomId, isEnter);
    },
    stop() {
        subscribers["messages-received"] = [];
        subscribers["status-changed"] = [];
        cleanUp();
        source?.close();
    },
    subscribe(eventName: EventName, callback: MessagesReceivedSubscriberProps | StatusChangedSubscriberProps) {
        //@ts-ignore
        subscribers[eventName].push(callback);
    },
    unsubscribe(eventName: EventName, callback: MessagesReceivedSubscriberProps | StatusChangedSubscriberProps) {
        //@ts-ignore
        subscribers[eventName] = subscribers[eventName].filter(s => s !== callback);
    },
    seat(authToken: string, roomId: number) {
        return instance.post('/game/seat', {}, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
            params: {
                roomId: roomId,
            }
        }).then((responce) => responce.data)
        .catch((e) => e);
    },
    validate(authToken: string, amount: number, currency: string) {
        return instance.get('/game/validate', {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
            params: {
                amount: amount,
                currency: currency
            }
        }).then(responce => responce.data);
    },
    leave(authToken: string, roomId: number) {
        return instance.post('/game/leave', {}, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
            params: {
                roomId: roomId
            }
        }).then((responce) => responce.data);
    },
    makeBet(authToken: string, roomId: number, userName: string, bet: number, amount: number) {
        return instance.post('/game/make-bet', {
            roomId: roomId,
            bet: {
                userName: userName,
                bet: bet,
                amount: amount
            },
        },
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                }
            }).then(responce => responce.data);
    },
    getCurrentBet(authToken: string, roomId: number) {
        return instance.get('/game/current-bet', {
            params: {
                roomId: roomId,
            },
            headers: { 'Authorization': `Bearer ${authToken}` },
        }).then(responce => responce.data);
    }
}

export type GameStatus = "NEW" | "WAITING" | "PRE_BET" | "SHOW_BETS" | "MAKE_BET" | "STARTED" | "ENDED";

export type GameDataProps<T extends FinishPayloadType | GamePayloadType> = {
    roomId: number;
    status: GameStatus;
    payload: T;
    timestamp: string;
    message: string
};

export type FinishPayloadType = {
    rate: number
    timestamp: string;
    leaders: PlayerFinish[];
}

export type PlayerFinish = {
    place: number,
    img: string,
    name: string,
    bet: number,
    currency: string,
    prize: number,
}

export type GamePayloadType = {
    type: number;
    amount: number;
    currencies: string;
    info: UserDataType[];
}

export type UserDataType = {
    userId: number;
    playerName: string | null;
    picture: string;
    bet: null | string;
    role: "VIEWER" | "PLAYER"
    difference: null | string;
}