
import { FC, memo, useCallback, useState } from 'react';
import { Column } from '../../сomponents/column';
import { Blur } from './onboarding.styled';
import { Image } from '../../ui-kit/image';
import { Text } from '../../ui-kit/text';

import coinSlide from '../../ui-kit/assets/Farm.png';
import tornamentsIcon from '../../ui-kit/assets/tornaments.png';
import referralIcom from "../../ui-kit/assets/referrals-onboardin.png";
import wheelIcon from "../../ui-kit/assets/wheel.png";

import { themes } from '../../themes/theme';
import { Button } from '../../ui-kit/button';
import { Row } from '../../сomponents/row';
import { useAppDispatch } from '../../hooks/use-typed-selector';
import { updateFirstTime } from '../../store/reducers/user-slice';
import { useUser } from '../../hooks/user-hook';

export type OnboardingViewProps = {};
export const OnboardingView: FC<OnboardingViewProps> = memo(() => {

  const [state, setState] = useState<number>(0)
  const { authToken } = useUser()
  const dispatch = useAppDispatch();
  const nextSlide = useCallback(() => {
    if (state < 3) {
      setState(prev => prev += 1);
    }
    else {
      dispatch(updateFirstTime({ authToken: authToken }));
    }
  }, [authToken, dispatch, state])

  return (
    <Column style={{ height: "100vh", position: "relative", overflow: "hidden", background: "#1C1A22" }}>
      <Blur bottom={50} right={-25} />
      <Blur top={30} right={25} />
      <Blur top={200} left={0} />

      {state === 0 && <SlideFirst onClick={nextSlide} />}
      {state === 1 && <SlideSecond onClick={nextSlide} />}
      {state === 2 && <SlideThree onClick={nextSlide} />}
      {state === 3 && <SlideFour onClick={nextSlide} />}

    </Column>
  );
});

export type SlideProps = {
  onClick: () => void;
}
export const SlideFirst: FC<SlideProps> = memo(({ onClick }) => {
  return (
    <Column verticalAlign="center" horizontalAlign="center" style={{ height: "100%", position: "relative", transition: "0.5s", overflow: "auto" }}>
      <Image style={{ objectFit: "contain" }} size={280} src={coinSlide} />
      <Text align="center" themeFont={themes.fonts.h1}>Farm <span style={{ color: themes.colors.secondary }}>BTRT</span> <br></br> tokens</Text>
      <Text themePadding={[10, 25]} align="center" themeFont={themes.fonts.ht1}><span style={{ color: themes.colors.primary }}>Farm BTRT</span>, native Bitrate token, to <span style={{ color: themes.colors.primary }}>join tournaments</span> and participate in Airdrop</Text>
      <Button style={{ position: "absolute", left: "calc(50% - 92px)", bottom: "30px" }} height={38} width={184} onClick={onClick}>
        <Text themeColor="#1C1A22" align="center" themeFont={themes.fonts.h3}>Next</Text>
      </Button>
    </Column>
  )
})

export const SlideSecond: FC<SlideProps> = memo(({ onClick }) => {
  return (
    <Column verticalAlign="center" horizontalAlign="center" style={{ height: "100%", position: "relative", overflow: "auto" }}>
      <Text align="center" themeFont={themes.fonts.h1}>Tourna<span style={{ color: themes.colors.secondary }}>ments</span></Text>
      <Image style={{ objectFit: "contain" }} size={280} src={tornamentsIcon} />
      <Text themePadding={[10, 25]} align="center" themeFont={themes.fonts.ht1}>
        Enter tournaments
        The <span style={{ color: themes.colors.primary }}>closest prediction</span> wins the pot
        Test your skills!
      </Text>
      <Button style={{ position: "absolute", left: "calc(50% - 92px)", bottom: "30px" }} height={38} width={184} onClick={onClick}>
        <Text themeColor="#1C1A22" align="center" themeFont={themes.fonts.h3}>Next</Text>
      </Button>
    </Column>
  )
})

export const SlideThree: FC<SlideProps> = memo(({ onClick }) => {
  return (
    <Column verticalAlign="center" horizontalAlign="center" style={{ height: "100%", position: "relative", overflow: "auto" }}>
      <Column style={{ position: "relative" }} horizontalAlign="center" verticalAlign="center">
        <Row style={{ width: "100%", marginLeft: "-150px" }} horizontalAlign="center" verticalAlign="center">
          <Image style={{ objectFit: "contain" }} height={300} width={300} src={wheelIcon} />
          <Text style={{ transform: "rotate(10deg)" }} align="center" themeFont={themes.fonts.h2}>Fortune<br></br><span style={{ color: themes.colors.secondary }}>Wheel</span></Text>
        </Row>
      </Column>
      <Text themePadding={[10, 25]} align="center" themeFont={themes.fonts.ht1}>
        <span style={{ color: themes.colors.primary }}>Spin</span> the Fortune Wheel.
          <span style={{ color: themes.colors.primary }}>Grab</span> BTRT, USDT, NFTs, and more.
      </Text>
      <Button style={{ position: "absolute", left: "calc(50% - 92px)", bottom: "30px" }} height={38} width={184} onClick={onClick}>
        <Text themeColor="#1C1A22" align="center" themeFont={themes.fonts.h3}>Next</Text>
      </Button>
    </Column>
  )
})

export const SlideFour: FC<SlideProps> = memo(({ onClick }) => {
  return (
    <Column verticalAlign="center" horizontalAlign="center" style={{ height: "100%", position: "relative", overflow: "auto" }}>
      <Text align="center" themeFont={themes.fonts.h1}>Earn more with <br></br><span style={{ color: themes.colors.secondary }}>Referrals</span></Text>
      <Image style={{ objectFit: "contain" }} height={270} width={270} src={referralIcom} />
      <Text themePadding={[10, 25]} align="center" themeFont={themes.fonts.ht1}>
        <span style={{ color: themes.colors.primary }}>Invite friends</span> and earn
        % of their balance as rewards. The more you refer, the more you <span style={{ color: themes.colors.primary }}>earn</span>
      </Text>
      <Button style={{ position: "absolute", left: "calc(50% - 92px)", bottom: "30px" }} height={38} width={184} onClick={onClick}>
        <Text themeColor="#1C1A22" align="center" themeFont={themes.fonts.h3}>Next</Text>
      </Button>
    </Column>
  )
})