import { useQuery } from "@tanstack/react-query";
import { WalletResponseDepositEstimatePrice } from "../../api/wallet/dto/response";
import { WalletGetDepositEstimatePrice } from "../../api/wallet/dto/request";
import { queryKeys } from "../queryKeys";
import { apiGetEsimatePrice } from "./lib/apiGetEstimatePrice";

type Props = {
  authToken: string;
  amountUSD: string;
  amount: string;
  currencyFrom: string;
  currencyTo: string;
  enabled?: boolean;
};

export const useGetEstimatePrice = ({
  authToken,
  amount,
  amountUSD,
  currencyFrom,
  currencyTo,
  enabled = true,
}: Props) => {
  const { data, isError, isFetched, isLoading, error } = useQuery<
    WalletResponseDepositEstimatePrice,
    WalletGetDepositEstimatePrice
  >({
    queryKey: queryKeys.wallet.depositEstimatePrice(
      currencyFrom,
      amountUSD,
      amount,
      currencyTo
    ),
    queryFn: async () =>
      apiGetEsimatePrice(authToken, amount, currencyFrom, currencyTo),
    enabled: enabled && amount !== "",
  });

  return {
    data,
    isError,
    isFetched,
    isLoading,
    error,
  };
};
