import { apiConfig } from "..";
import { apiRoutes } from "../apiRoutes";
import { CustomResponse } from "../types";
import { Currency } from "./dto/common";
import { WalletGetDepositEstimatePrice, WalletGetDepositMinAmount, WalletGetFee, WalletGetValidateAddress, WalletPostDeposit, WalletPostPreWithdraw, WalletPostVerifyWithdraw } from "./dto/request";
import { WalletResponseDeposit, WalletResponseDepositEstimatePrice, WalletResponseDepositMinAmount, WalletResponseFee, WalletResponseHistory } from "./dto/response";

export const walletApi = (authToken: string) => {
    return {
        getHistory() {
            return apiConfig.get<
                void,
                CustomResponse<WalletResponseHistory>
            >(apiRoutes.wallet.history, {
                headers: { 'Authorization': `Bearer ${authToken}` }
            })
        },
        depositAddress(currency: string, amountUSD?: string, amount?: string) {
            return apiConfig.post<
                WalletPostDeposit,
                CustomResponse<WalletResponseDeposit>
            >(apiRoutes.wallet.deposit, null, {
                params: {
                    currency: currency.toLowerCase(),
                    payAmount: amount,
                    priceAmount: amountUSD
                },
                headers: { 'Authorization': `Bearer ${authToken}` }
            })
        },
        getMinAmountOfDeposit(currency_from: string) {
            return apiConfig.get<
                WalletGetDepositMinAmount,
                CustomResponse<WalletResponseDepositMinAmount>
            >(apiRoutes.wallet.depositMinAmount, {
                params: {
                    currency_from: currency_from
                },
                headers: { 'Authorization': `Bearer ${authToken}` }
            })
        },
        validateAddressWithdrawal(address: string, currency: string) {
            return apiConfig.get<
                WalletGetValidateAddress,
                CustomResponse<void>
            >(apiRoutes.wallet.validateAddress, {
                params: {
                    address: address,
                    currency: currency,
                },
                headers: { 'Authorization': `Bearer ${authToken}` }
            })
        },
        preWithdrawal(amount: string, currency: string, address: string) {
            return apiConfig.post<
                WalletPostPreWithdraw,
                CustomResponse<void>
            >
                (apiRoutes.wallet.preWithdraw, null, {
                    params: {
                        amount: amount,
                        currency: currency,
                        address: address,
                    },
                    headers: { 'Authorization': `Bearer ${authToken}` }
                })
        },
        verifyWithdraw(code: string) {
            return apiConfig.post<
                WalletPostVerifyWithdraw,
                CustomResponse<boolean>
            >
                (apiRoutes.wallet.verifyWithdraw, null, {
                    params: {
                        code: code
                    },
                    headers: { 'Authorization': `Bearer ${authToken}` }
                })
        },
        getFeeWithdraw(amount: string, currency: Currency) {
            return apiConfig.get<
                WalletGetFee,
                CustomResponse<WalletResponseFee>
            >(apiRoutes.wallet.fee, {
                params: {
                    amount: amount,
                    currency: currency
                },
                headers: { 'Authorization': `Bearer ${authToken}` }
            })
        }
        ,
        getEstimatePrice(amount: string, currencyFrom: string, currencyTo: string) {
            return apiConfig.get
                <WalletGetDepositEstimatePrice,
                    CustomResponse<WalletResponseDepositEstimatePrice>
                >
                (apiRoutes.wallet.depositEstimatePrice, {
                    params: {
                        amount: Number(amount),
                        currencyFrom: currencyFrom,
                        currencyTo: currencyTo
                    },
                    headers: { 'Authorization': `Bearer ${authToken}` }
                })
        }
    }
}