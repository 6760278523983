import { memo, useCallback, useState } from "react";
import { FC } from "react";
import Logo from '../../../images/bitrate-logo-new.svg';
import { Wrapper } from "../../../сomponents/wrapper";
import { Column } from "../../../сomponents/column";
import { Spacing } from "../../../сomponents/spacing";
import { Text } from "../../../сomponents/text"
import { theme } from "../../../themes/theme";
import { Input } from "../../../сomponents/input/input";
import { LineText } from "../../../сomponents/line-text";
import { Row } from "../../../сomponents/row";
import { LinkButton } from "../../../сomponents/link-button";
import { RectangleButton } from "../../../сomponents/rectangle-button";
import { useForm } from "react-hook-form";
import { InputMessage } from "../../../сomponents/input-message";
import { LogoStyled } from "../../../сomponents/logo";
import { Toggle } from "../../../сomponents/toggle";
import { PhoneInputParent } from "../../../сomponents/phone-input";
import { Pressable } from "../../../сomponents/pressable";
import arrow from "../../../images/arrow.svg";
import { Image } from "../../../сomponents/image"
import { RotateArrow } from "./registration-create.styles";
import { Checkbox } from "../../../сomponents/checkbox";
import { useMediaQuery } from "react-responsive";
import { RegistrationState } from "../../../store/reducers/registration-slice";
import { RegistrationContainer } from "../registration-log-in/registration-log-in.styles";
import { VerificationPopup } from "../../../сomponents/verification-popup";
import { VerifySecurityState } from "../../../store/reducers/personal-account/security-verify";
import { UserTelegram } from "../../../store/reducers/auth-slice";

export type RegistrationCreateViewProps = {
    onRegistrationLogin: () => void;
    onAuthByTelegram: (user: UserTelegram) => void;
    resetData: () => void;
    tab: "Down" | "Left";
    state: RegistrationState;
    onRegister: (variant: "Email" | "Phone", onSuccessfully?: () => void) => void;
    onUserName: (variant: 'email' | 'tel', value: string) => void;
    onPassword: (value: string) => void;
    onConfirmPassword: (value: string) => void;
    onReferralCode: (value: string) => void;
    onReferalOpen: () => void;
    verifySecurityState: VerifySecurityState;
    sendCode: (value: 'app' | 'email' | 'sms' | 'none') => void;
    setDigits: (value: [string, string, string, string, string, string]) => void;
    confirmCodeClick: (value: 'app' | 'email' | 'sms' | 'none', onSuccessfully?: () => void) => void;
    resetDataFromPopup: () => void;
}

export const RegistrationCreateView: FC<RegistrationCreateViewProps> = memo(({
    onRegistrationLogin,
    state,
    onUserName,
    onPassword,
    onConfirmPassword,
    onReferralCode,
    verifySecurityState,
    sendCode,
    setDigits,
    confirmCodeClick,
    onRegister,
    resetDataFromPopup,
    tab, onReferalOpen,
    resetData,
    onAuthByTelegram
}) => {
    const [toggle, setToggle] = useState<"left" | "right">("left");
    const onClickToggle = useCallback(() => {
        resetData();
        setToggle(toggle === "left" ? "right" : "left");
    }, [toggle, resetData]);

    const {
        register,
        control,
    } = useForm({ mode: "onChange" });

    const setUserName = useCallback((value: string) => {
        onUserName(toggle === 'left' ? 'email' : 'tel',value);
    }, [onUserName,toggle]);

    const [isOpenVerify, setIsOpenVerify] = useState<boolean>(false);
    const isMobile = useMediaQuery({ maxWidth: theme.toMobileSize });

    const openAndSendCode = useCallback(() => {
        sendCode(toggle === 'left' ? 'email' : 'sms');
        setIsOpenVerify(!isOpenVerify);
    }, [sendCode, toggle,setIsOpenVerify, isOpenVerify]);

    const [checked, setChecked] = useState<Boolean>(false);
    const onChecked = useCallback(() => {
        setChecked(!checked);
    }, [checked]);

    const onContinue = useCallback(() => {
        if (checked) {
            if(isOpenVerify) {
                resetDataFromPopup();
                setIsOpenVerify(!isOpenVerify);
            } else {
                onRegister(toggle === 'left' ? 'Email' : 'Phone',openAndSendCode);
            }
        }
    }, [isOpenVerify, toggle, onRegister, openAndSendCode,resetDataFromPopup,checked]);

    const onConfirm = useCallback(() => {
        confirmCodeClick(toggle === 'left' ? 'email' : 'sms');
    }, [confirmCodeClick,toggle]);

    return (
        <Wrapper>
            <Column horizontalAlign="center">
                <Spacing themeSpace={70} themeMobileSpace={20} variant="Column" />
                <LogoStyled src={Logo} />
                <Spacing variant="Column" themeMobileSpace={20} themeSpace={55} />
                <Text transform="uppercase" themeFont={theme.fonts.b}>
                    Create Account
                </Text>
                <Spacing variant="Column" themeMobileSpace={20} themeSpace={45} />
                <Toggle onButtonClick={onClickToggle} varinat={toggle} leftText="Email" rightText="Phone Number" />
                <Spacing variant="Column" themeMobileSpace={20} themeSpace={45} />
                <RegistrationContainer>
                    {toggle === "left" ?
                        <Input
                            register={register}
                            required={true}
                            setValue={setUserName}
                            value={state.email}
                            name="email"
                            maxLength={{ value: 30, message: 'Email must be at least 30 characters', }}
                            typeButton="input"
                            placeholder="Email"
                            placeholderInput="Enter Email"
                            errorInput={state.errorEmail && <InputMessage themeColor={theme.colors.error} text={state.errorEmail} />}
                        /> :
                        <PhoneInputParent
                            control={control}
                            setValue={setUserName}
                            valueInput={state.tel}
                            name="phoneNumber"
                            errorInput={state.errorTel && <InputMessage themeColor={theme.colors.error} text={state.errorTel} />}
                        />
                    }
                    <Spacing variant="Column" themeMobileSpace={30} themeSpace={30} />
                    <Input
                        register={register}
                        name="password"
                        minLength={{ value: 8, message: 'Password must be at least 8 characters', }}
                        type="password"
                        setValue={onPassword}
                        value={state.password}
                        typeButton="password"
                        placeholder="Password"
                        placeholderInput="Enter Password"
                        errorInput={state.errorPassword && <InputMessage themeColor={theme.colors.error} text={state.errorPassword} />}
                    />
                    <Spacing variant="Column" themeMobileSpace={30} themeSpace={30} />
                    <Input
                        register={register}
                        name="passwordConfirm"
                        type="password"
                        typeButton="password"
                        setValue={onConfirmPassword}
                        value={state.confirmPassword}
                        minLength={{ value: 8, message: 'Password must be at least 8 characters', }}
                        placeholder="Confirm Password"
                        placeholderInput="Confirm Password"
                        errorInput={state.errorConfirmPassword && <InputMessage themeColor={theme.colors.error} text={state.errorConfirmPassword} />}
                    />
                    <Spacing variant="Column" themeMobileSpace={30} themeSpace={35} />
                    <Column horizontalAlign='center'>
                        <Pressable onPress={onReferalOpen}>
                            <Row horizontalAlign="center">
                                <Text themeFont={theme.fonts.ht2}>
                                    Enter Referral/Promo Code
                                </Text>
                                <Spacing variant="Row" themeSpace={10} />
                                <RotateArrow tab={tab}>
                                    <Image src={arrow} />
                                </RotateArrow>
                            </Row>
                        </Pressable>
                    </Column>
                    {tab === "Left" &&
                        <Column horizontalAlign="center">
                            <Spacing variant="Column" themeMobileSpace={15} themeSpace={10} />
                            <Input
                                register={register}
                                required={false}
                                name="referal"
                                setValue={onReferralCode}
                                value={state.referralCode}
                                maxLength={{ value: 8, message: 'Referal must be at least 8 characters', }}
                                typeButton="input"
                                placeholderInput="Enter Code"
                            />
                        </Column>
                    }
                    <Spacing variant="Column" themeMobileSpace={15} themeSpace={35} />
                    <LineText />
                    <Spacing variant="Column" themeMobileSpace={15} themeSpace={35} />
                    <Row horizontalAlign="center">

                    </Row>
                    <Spacing variant="Column" themeMobileSpace={15} themeSpace={35} />
                    <Pressable onPress={onChecked}>
                        <Row horizontalAlign="flex-start" verticalAlign="flex-start" style={{ width: '100%' }}>
                            <Checkbox checked={checked} />
                            <Spacing themeSpace={10} variant="Row" />
                            <Text style={{ textAlign: isMobile ? "left" : "initial" }} themeColor={theme.colors.surfaceGray} themeFont={theme.fonts.t}>
                                I have read and agree to the <span style={{ color: theme.colors.primary }}>Terms and Conditions</span> and
                                <span style={{ color: theme.colors.primary }}> Privacy Policy</span>
                            </Text>
                        </Row>
                    </Pressable>
                    <Spacing variant="Column" themeMobileSpace={15} themeSpace={35} />
                    <Column horizontalAlign="center">
                        <RectangleButton width="100%" height={isMobile ? 40 : 60} onClick={onContinue} variant="primary">
                            <Text themeColor={theme.colors.background} transform="uppercase" themeFont={theme.fonts.ht1}>
                                Continue
                            </Text>
                        </RectangleButton>
                        <Spacing variant="Column" themeMobileSpace={15} themeSpace={35} />
                        <LinkButton themeColor={theme.colors.surfaceGray} onClick={onRegistrationLogin} text="Have an account? Log in" />
                        <Spacing variant="Column" themeMobileSpace={15} themeSpace={15} />
                    </Column>
                </RegistrationContainer>
                <Spacing variant="Column" themeMobileSpace={20} themeSpace={100} />
                <Text themeColor={theme.colors.surfaceGray} themeFont={theme.fonts.t}>
                    © 2023-2024 BITRATE. All rights reserved
                </Text>
                <Spacing variant="Column" themeMobileSpace={10} themeSpace={30} />
            </Column>
            <VerificationPopup 
                isOpenVerificationPopup={isOpenVerify}
                closePopup={onContinue}
                onBack={onContinue}
                setDigits={setDigits} 
                onConfirmClick={onConfirm} 
                verifySecurityState={verifySecurityState} 
                sendCode={() => sendCode(toggle === 'left' ? 'email' : 'sms')}
                typeVerify={toggle === 'left' ? 'email' : 'sms'}
                recipient={state.email}
            />
        </Wrapper>
    );
});