import { useQuery } from "@tanstack/react-query";
import { WalletResponseDeposit } from "../../api/wallet/dto/response";
import { WalletPostDeposit } from "../../api/wallet/dto/request";
import { queryKeys } from "../queryKeys";
import { apiDepositAddress } from "./lib/apiDepositAddress";

type Props = {
  amount?: string;
  amountUSD?: string;
  authToken: string;
  currency: string;
  enabled?: boolean;
};

export const useDepositAddress = ({
  amount,
  amountUSD,
  authToken,
  currency,
  enabled = true,
}: Props) => {
  const { data, isError, isFetched, isLoading, isPending, error } = useQuery<
    WalletResponseDeposit | null,
    WalletPostDeposit
  >({
    queryKey: queryKeys.wallet.deposit(currency, amount),
    queryFn: async () => apiDepositAddress({currency, amount, amountUSD, authToken }),
    enabled: enabled && amount !== "",
  });
  return {
    data,
    isError,
    isFetched,
    isLoading,
    isPending,
    error,
  };
};
