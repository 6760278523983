import { Currency } from "../api/wallet/dto/common";

export const queryKeys = {
    wallet : {
        verifyWithdraw : (currency: string, address: string) => ['verify-withdraw', currency, address],
        history: () => ['history'],
        preWithdraw: (currency: string) => ['pre-withdraw', currency],
        deposit:(currency: string, payAmount ?: string) => ['deposit', currency, payAmount ],
        validateAddress:(currency: Currency) => ['wallet', currency],
        status:() => ['wallet'],
        fee: (currency: Currency, amount: string) => ['fee', currency, amount],
        depositMinAmount: (currency: string) => ['min-amount', currency],
        depositEstimatePrice: (currency: string, amountUSD: string, amount: string, currencyTo: string) => ['wallet', currency, amountUSD, amount, currencyTo]
    }
}