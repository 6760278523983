import { ErrorResponseDto } from "../../api/types";
import { useMutation } from "@tanstack/react-query";
import { apiSendVerifyWithdraw } from "./lib/apiSendVerifyWithdraw";
import { WalletPostVerifyWithdraw } from "../../api/wallet/dto/request";

type Props = {
  authToken: string;
  code: string;
};

export const useVerifyWithdraw = ({ authToken, code }: Props) => {
  const { data, isPending, isError, isSuccess, mutateAsync, error } = useMutation<
    boolean,
    ErrorResponseDto,
    WalletPostVerifyWithdraw
  >({
    mutationKey: ["verify-withdrawal"],
    mutationFn: async () => apiSendVerifyWithdraw({ code, authToken }),
    onError: (err) => {
      console.error("Ошибка в useVerifyWithdraw:", err);
    },
  });

  return {
    data,
    isPending,
    error,
    isError,
    isSuccess,
    mutateAsync,
  };
};
